/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChatPostListPagination = /* GraphQL */ `
  query GetChatPostListPagination(
    $params: GetChatPostListPaginationRequestDto
  ) {
    getChatPostListPagination(params: $params) {
      data {
        id
        corporationId
        affiliationProject
        postType
        chatThreadId
        content
        delegateUserId
        fileUrl
        fileSize
        status
        footPrint {
          userList
        }
        history {
          content
          updatedAt
        }
        seq
        createdAt
        updatedAt
        creatorId
        mention
        reaction
        thumbnailInfo
        deliverInfo {
          status
          deliverAt
          sender
          __typename
        }
        ticketId
      }
      from
    }
  }
`;
export const getTicketList = /* GraphQL */ `
  query GetTicketList($params: GetTicketListRequestDto) {
    getTicketList(params: $params) {
      id
      ticketId
      dataType
      dataID
      dataLang
      dataValue
      usage
      type
      affiliationOrganization
      affiliationProject
      additionalCustomer
      customer
      additionalReadPermission
      readPermission
      additionalWritePermission
      writePermission
      createdAt
      updatedAt
      deadLine
      creator
      updator
      personInCharge
      proxiedUser
      outline
      description
      memo
      attachment {
        fileUrl
        fileSize
        fileName
        createdAt
        thumbnailInfo
        idTicketFileKnowledge
        isPdf
        splitPdf
        isCreatingPageKnowledge
      }
      suffixOfAttachments
      liveOrArchived
      status
      fields
      knowledgeCanvas
      chatThreadWithCustomer
      chatThreadInProject
      collaborators
      customersCustomer
      sprint
      priority
      processingOrder
      projectOrigin
      projectTarget
      parent
      children
      child
      ticketType
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      invitationFlag
      approveFlag
      approvePageLink {
        link
        expireTime
      }
      projectTargetName
      projectOriginName
      createrUserName
      updatorUserName
      statusDetail
      listTicketChild
      parentTicket
      proxiedUserName
      publicType
      publicRange
      public
      customerName
      inhouseCollaboratorTicketId
      inhouseCustomerTicketId
      externalCollaboratorTicketId
      externalCustomerTicketId
      formId
      isExternalTicket
      anonymousUserInfo {
        mailAddress
        reEmailAddress
        firstName
        lastName
        firstNameKana
        lastNameKana
        type
        isCustomerInvitationSettingOff
      }
      anonymousQaStatus
      personInChargeInfo {
        id
        avatar
        firstName
        lastName
        shortName
        fullName
        affiliationOrganizationInfo {
          id
          active
          name
          languages
        }
        active
      }
      updatedForCustomer
      updatedForDesk
      settingTitle {
        text
        id
        isBreak
        sysField
      }
      footPrint
      viewPermission
      additionalViewPermission
      editPermission
      additionalEditPermission
      chatPermission
      deletedAt
      activeB2BSupportList
      activeB2BCustomerList
      isDelete
      hasOneSideBlockedOrg
      hasOneSideDeletedOrg
      listBlockedOrg
      listDeletedOrg
      deskService
      knowledgeService
      taskManagementService
      publicService
      promptFlag
      contentPagePdf
      totalToken
      isSynonym
      errorMessage
      convertPDFOrg
      bookmarkInfo
      metaData
      deliverStatus
      deliverInfo
      listTicketEmailChild
      promptData
    }
  }
`;
export const getTicketCustomerList = /* GraphQL */ `
  query GetTicketCustomerList($params: GetTicketCustomerListRequestDto) {
    getTicketCustomerList(params: $params) {
      responseTicketList {
        id
        usage
        type
        affiliationOrganization
        affiliationProject
        customer
        additionalReadPermission
        readPermission
        additionalWritePermission
        writePermission
        createdAt
        updatedAt
        deadLine
        deletedAt
        creator
        updator
        personInCharge
        proxiedUser
        outline
        description
        memo
        attachment {
          fileUrl
          fileSize
          fileName
          createdAt
          thumbnailInfo
          idTicketFileKnowledge
          isPdf
          splitPdf
          isCreatingPageKnowledge
        }
        suffixOfAttachments
        liveOrArchived
        status
        fields
        knowledgeCanvas
        chatThreadWithCustomer
        chatThreadInProject
        collaborators
        customersCustomer
        sprint
        priority
        processingOrder
        projectOrigin
        projectTarget
        parent
        child
        ticketType
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        invitationFlag
        approveFlag
        statusDetail {
          name
          structure
        }
        affiliationProjectInfo {
          name
          image
          shortName
        }
        lastMessage {
          userId
          chatPostId
        }
        lastMessageInfo {
          unreadMessageNumber
          message
          avatar
          chatThreadId
          userDetail
        }
        public
        titleLineNumber
        personInChargeInfo {
          id
          avatar
          firstName
          lastName
          shortName
          picFieldPrivateStatus
          picFieldActive
          active
          fullName
        }
        updatedForCustomer {
          ticketEvent {
            updatedTime
            updatedId
          }
          chatEvent {
            updatedTime
            updatedId
          }
        }
        updatedForDesk {
          ticketEvent {
            updatedTime
            updatedId
          }
          chatEvent {
            updatedTime
            updatedId
          }
        }
        updatedTimeTicket {
          updatedTime
          updatedId
        }
        settingTitle {
          text
          id
          isBreak
          sysField
        }
        anonymousUserInfo {
          mailAddress
          reEmailAddress
          firstName
          lastName
          firstNameKana
          lastNameKana
          type
          isCustomerInvitationSettingOff
        }
        anonymousQaStatus
      }
      responseHelpDeskList {
        id
        helpDeskType
        displayName
        shortName
      }
      totalTickets
    }
  }
`;
export const prepareDataForTicketDetail = /* GraphQL */ `
  query PrepareDataForTicketDetail(
    $prepareDataForTicketDetailRequestDto: PrepareDataForTicketDetailRequestDto!
  ) {
    prepareDataForTicketDetail(
      prepareDataForTicketDetailRequestDto: $prepareDataForTicketDetailRequestDto
    ) {
      detailTicket {
        id
        ticketId
        dataType
        dataID
        dataLang
        dataValue
        usage
        type
        affiliationOrganization
        affiliationProject
        additionalCustomer
        customer
        additionalReadPermission
        readPermission
        additionalWritePermission
        writePermission
        createdAt
        updatedAt
        deadLine
        creator
        updator
        personInCharge
        proxiedUser
        outline
        description
        memo
        attachment {
          fileUrl
          fileSize
          fileName
          createdAt
          thumbnailInfo
          idTicketFileKnowledge
          isPdf
          splitPdf
          isCreatingPageKnowledge
        }
        suffixOfAttachments
        liveOrArchived
        status
        fields
        knowledgeCanvas
        chatThreadWithCustomer
        chatThreadInProject
        collaborators
        customersCustomer
        sprint
        priority
        processingOrder
        projectOrigin
        projectTarget
        parent
        children
        child
        ticketType
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        invitationFlag
        approveFlag
        approvePageLink {
          link
          expireTime
        }
        projectTargetName
        projectOriginName
        createrUserName
        updatorUserName
        statusDetail
        listTicketChild
        parentTicket
        proxiedUserName
        publicType
        publicRange
        public
        customerName
        inhouseCollaboratorTicketId
        inhouseCustomerTicketId
        externalCollaboratorTicketId
        externalCustomerTicketId
        formId
        isExternalTicket
        anonymousUserInfo {
          mailAddress
          reEmailAddress
          firstName
          lastName
          firstNameKana
          lastNameKana
          type
          isCustomerInvitationSettingOff
        }
        anonymousQaStatus
        personInChargeInfo {
          id
          avatar
          firstName
          lastName
          shortName
          fullName
          affiliationOrganizationInfo {
            id
            active
            name
            languages
          }
          active
        }
        updatedForCustomer
        updatedForDesk
        settingTitle {
          text
          id
          isBreak
          sysField
        }
        footPrint
        viewPermission
        additionalViewPermission
        editPermission
        additionalEditPermission
        chatPermission
        deletedAt
        activeB2BSupportList
        activeB2BCustomerList
        isDelete
        hasOneSideBlockedOrg
        hasOneSideDeletedOrg
        listBlockedOrg
        listDeletedOrg
        deskService
        knowledgeService
        taskManagementService
        publicService
        promptFlag
        contentPagePdf
        totalToken
        isSynonym
        errorMessage
        convertPDFOrg
        bookmarkInfo
        metaData
        deliverStatus
        deliverInfo
        listTicketEmailChild
        promptData
      }
      selectedProjectIsProjectOrigin
      currentUserIsCreator
      fieldListData
      isApprovedExternalDeskRequest
      isApprovedExternalCustomerRequest
      usingFormName
      formDetail
      mappingDataIdToIdObj
      externalProjectInfo
      corporationOriginData {
        id
        active
        name
      }
    }
  }
`;
export const getTicketPdfDetail = /* GraphQL */ `
  query GetTicketPdfDetail($params: GetTicketPdfDetailRequestDto) {
    getTicketPdfDetail(params: $params) {
      id
      ticketType
      public
      usingFormName
      formDetail
      affiliationOrganization
      affiliationProject
      createdAt
      updatedAt
      outline
      defaultFields
      hyperLinkExtractText
      hyperLinkVector
      totalToken
      createrUserName
      updatorUserName
      deletedAt
      footPrint
      creator
      viewPermission
      editPermission
      activeB2BCustomerList
      activeB2BSupportList
      additionalViewPermission
      chatPermission
      bookmarkInfo
      errorMessage
    }
  }
`;
export const getDrillDownSearch = /* GraphQL */ `
  query GetDrillDownSearch($params: GetDrillDownSearchRequestDto) {
    getDrillDownSearch(params: $params) {
      data
    }
  }
`;
export const getUserNotificationList = /* GraphQL */ `
  query GetUserNotificationList($params: GetUserNotificationListRequestDto) {
    getUserNotificationList(params: $params) {
      page
      totalPage
      notificationList {
        id
        systemType
        affiliationOrganization
        affiliationProject
        notificationRange
        sourceObject
        objectType
        eventType
        sourceInfo
        footPrint
        creator
        createdAt
        updator
        updatedAt
      }
    }
  }
`;
export const getTicketKnowledgeList = /* GraphQL */ `
  query GetTicketKnowledgeList($params: GetTicketKnowledgeListRequestDto) {
    getTicketKnowledgeList(params: $params) {
      ticketKnowledgeListInfo {
        id
        usage
        type
        affiliationOrganization
        affiliationProject
        customer
        additionalReadPermission
        readPermission
        additionalWritePermission
        writePermission
        createdAt
        updatedAt
        deadLine
        deletedAt
        creator
        updator
        personInCharge
        proxiedUser
        outline
        description
        memo
        attachments {
          fileUrl
          fileSize
          fileName
          createdAt
          thumbnailInfo
          idTicketFileKnowledge
          isPdf
          splitPdf
          isCreatingPageKnowledge
        }
        suffixOfAttachments
        liveOrArchived
        status
        knowledgeCanvas
        chatThreadWithCustomer
        chatThreadInProject
        collaborators
        customersCustomer
        sprint
        priority
        processingOrder
        projectOrigin
        projectTarget
        parent
        child
        ticketType
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        invitationFlag
        approveFlag
        publicType
        publicRange
        footPrint
        footPrintDetail {
          firstName
          middleName
          lastName
          languages
          active
          id
          fullName
          avatar
          affiliationOrganization
          affiliationOrganizationInfo {
            id
            active
            name
            languages
          }
        }
        affiliationProjectInfo {
          id
          active
          affiliationOrganization
          name
          languages
        }
        creatorInfo {
          firstName
          middleName
          lastName
          languages
          active
          id
          fullName
          avatar
          affiliationOrganization
          affiliationOrganizationInfo {
            id
            active
            name
            languages
          }
        }
        updatorInfo {
          firstName
          middleName
          lastName
          languages
          active
          id
          fullName
          avatar
          affiliationOrganization
          affiliationOrganizationInfo {
            id
            active
            name
            languages
          }
        }
        viewPermission
        additionalViewPermission
        editPermission
        additionalEditPermission
        chatPermission
        publicProject
        publicOrganization
        publicCustomer
        publicSupport
        publicAll
        publicGuestCustomer
        public
        titleLineNumber
        settingTitle {
          text
          id
          isBreak
          sysField
        }
        activeB2BSupportList
        activeB2BCustomerList
        affiliationOrganizationInfo {
          id
          active
          name
          languages
        }
        totalUnreadMsg
        promptFlag
        contentPagePdf
        pageNo
        totalToken
        isSynonym
        privateBookmarkData {
          bookmarkId
          bookmarkAffiliationProject
          userId
          projectSetting
          createdAt
        }
        shareBookmarkData {
          bookmarkId
          bookmarkAffiliationProject
          userId
          projectSetting
          createdAt
        }
        totalPrivateBookmark
        numPrivateBookmark
        isBookmarkedDefaultBookmark
        bookmarkListText
      }
      totalTickets
      inviterUsingChatAI
    }
  }
`;
export const getTicketHelpDeskList = /* GraphQL */ `
  query GetTicketHelpDeskList($params: GetTicketHelpDeskListRequestDto) {
    getTicketHelpDeskList(params: $params) {
      ticketHelpDeskListInfo {
        id
        usage
        type
        affiliationOrganization
        affiliationProject
        customer
        additionalReadPermission
        readPermission
        additionalWritePermission
        writePermission
        createdAt
        updatedAt
        deadLine
        deletedAt
        creator
        updator
        personInCharge
        proxiedUser
        outline
        description
        memo
        attachment {
          fileUrl
          fileSize
          fileName
          createdAt
          thumbnailInfo
          idTicketFileKnowledge
          isPdf
          splitPdf
          isCreatingPageKnowledge
        }
        suffixOfAttachments
        liveOrArchived
        status
        knowledgeCanvas
        chatThreadWithCustomer
        chatThreadInProject
        collaborators
        customersCustomer
        sprint
        priority
        processingOrder
        projectOrigin
        projectTarget
        parent
        child
        ticketType
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        invitationFlag
        approveFlag
        publicType
        publicRange
        public
        titleLineNumber
        statusDetail {
          name
          structure
          currentStatus
          currentStatusField
        }
        statusItemList {
          id
          affiliationField
          affiliationOrganization
          affiliationProject
          name
          structure
          viewForCustomer
        }
        affiliationProjectInfo {
          name
          image
          shortName
          userNoticeImage
          corporationInfo {
            id
            active
            name
          }
        }
        lastMessage {
          userId
          chatPostId
        }
        lastMessageInfo {
          unreadMessageNumber
          message
          avatar
          chatThreadId
          userDetail
        }
        anonymousUserInfo {
          mailAddress
          reEmailAddress
          firstName
          lastName
          firstNameKana
          lastNameKana
          type
          isCustomerInvitationSettingOff
        }
        anonymousQaStatus
        personInChargeInfo {
          id
          avatar
          firstName
          lastName
          shortName
          picFieldPrivateStatus
          picFieldActive
          mail
          active
          fullName
        }
        creatorInfo {
          id
          avatar
          firstName
          lastName
          shortName
          picFieldPrivateStatus
          picFieldActive
          mail
          active
          fullName
        }
        isInternalTicket
        isExternalTicket
        updatedForCustomer
        updatedForDesk
        settingTitle {
          text
          id
          isBreak
          sysField
        }
      }
      totalTickets
    }
  }
`;
export const searchTicketKnowledgeList = /* GraphQL */ `
  query SearchTicketKnowledgeList(
    $params: SearchTicketKnowledgeListRequestDto
  ) {
    searchTicketKnowledgeList(params: $params) {
      id
      usage
      type
      affiliationOrganization
      affiliationProject
      customer
      additionalReadPermission
      readPermission
      additionalWritePermission
      writePermission
      createdAt
      updatedAt
      deadLine
      deletedAt
      creator
      updator
      personInCharge
      proxiedUser
      outline
      description
      memo
      attachments {
        fileUrl
        fileSize
        fileName
        createdAt
        thumbnailInfo
        idTicketFileKnowledge
        isPdf
        splitPdf
        isCreatingPageKnowledge
      }
      suffixOfAttachments
      liveOrArchived
      status
      fields
      listFieldsValue
      knowledgeCanvas
      chatThreadWithCustomer
      chatThreadInProject
      collaborators
      customersCustomer
      sprint
      priority
      processingOrder
      projectOrigin
      projectTarget
      parent
      child
      ticketType
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      invitationFlag
      approveFlag
      publicType
      publicRange
      affiliationProjectInfo {
        name
        languages
      }
      creatorInfo {
        firstName
        middleName
        lastName
        languages
      }
      updatorInfo {
        firstName
        middleName
        lastName
        languages
      }
      promptFlag
      contentPagePdf
      totalToken
      isSynonym
    }
  }
`;
export const getCorporationList = /* GraphQL */ `
  query GetCorporationList($params: GetCorporationListRequestDto) {
    getCorporationList(params: $params) {
      id
      usage
      affiliationOrganization
      image
      logoFileName
      active
      name
      languages
      autoTranslation
      rootFlag
      owner
      deskService
      knowledgeService
      taskManagementService
      publicService
      organizationDomain
      contractType
      contractDate
      lastDayOfContract
      creditCard
      ZIPcode
      address
      mail
      tel
      departmentName
      activatedFunctions
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      inviter
      inviterOrganizationDomain
      corporationInvited
      creator
      createdAt
      updator
      updatedAt
      versionId
      contractStatus
      inviterUsingChatAI
      inviterSetting
      lincenseKeyStatus
      corporationSetting
    }
  }
`;
export const getCorporationDetail = /* GraphQL */ `
  query GetCorporationDetail($params: GetCorporationDetailRequestDto) {
    getCorporationDetail(params: $params) {
      id
      usage
      affiliationOrganization
      image
      logoFileName
      active
      name
      languages
      autoTranslation
      rootFlag
      owner
      deskService
      knowledgeService
      taskManagementService
      publicService
      organizationDomain
      contractType
      contractDate
      lastDayOfContract
      creditCard
      ZIPcode
      address
      mail
      tel
      departmentName
      activatedFunctions
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      inviter
      inviterOrganizationDomain
      corporationInvited
      creator
      createdAt
      updator
      updatedAt
      versionId
      contractStatus
      inviterUsingChatAI
      inviterSetting
      lincenseKeyStatus
      corporationSetting
    }
  }
`;
export const getUserList = /* GraphQL */ `
  query GetUserList($params: GetUserListRequestDto) {
    getUserList(params: $params) {
      id
      usage
      affiliationOrganization
      image
      active
      firstName
      middleName
      lastName
      languages
      privilege
      type
      mail
      tel
      projectInuse
      readNotificationAt
      workSpace
      creator
      createdAt
      updator
      updatedAt
      settingNotification
      forgotPasswordSessionExpireAt
      expireAt
      projectList {
        id
        projectId
        projectType
        userPermission
      }
      isGuest
      userSetting
      projectRejected
      workLastForm
    }
  }
`;
export const getUserDetail = /* GraphQL */ `
  query GetUserDetail($params: GetUserDetailRequestDto) {
    getUserDetail(params: $params) {
      id
      usage
      affiliationOrganization
      image
      active
      firstName
      middleName
      lastName
      languages
      privilege
      type
      mail
      tel
      projectInuse
      readNotificationAt
      workSpace
      creator
      createdAt
      updator
      updatedAt
      settingNotification
      forgotPasswordSessionExpireAt
      expireAt
      projectList {
        id
        projectId
        projectType
        userPermission
      }
      isGuest
      userSetting
      projectRejected
      workLastForm
    }
  }
`;
export const getProjectList = /* GraphQL */ `
  query GetProjectList($params: GetProjectListRequestDto) {
    getProjectList(params: $params) {
      id
      projectId
      usage
      affiliationOrganization
      image
      active
      name
      languages
      autoTranslation
      id2
      privilege
      type
      mail
      tel
      projectInvite
      projectInvited
      iframeSetting
      isDesk
      isPublic
      isGuest
      publicKnowledgeSiteSetting
      chatBotCategorySetting
      chatbotSetting
      projectAlias
      userLogCache
      creator
      createdAt
      updator
      updatedAt
      projectSetting
      mailSetting
    }
  }
`;
export const getProjectDetail = /* GraphQL */ `
  query GetProjectDetail($params: GetProjectDetailRequestDto) {
    getProjectDetail(params: $params) {
      id
      projectId
      usage
      affiliationOrganization
      image
      active
      name
      languages
      autoTranslation
      id2
      privilege
      type
      mail
      tel
      projectInvite
      projectInvited
      iframeSetting
      isDesk
      isPublic
      isGuest
      publicKnowledgeSiteSetting
      chatBotCategorySetting
      chatbotSetting
      projectAlias
      userLogCache
      creator
      createdAt
      updator
      updatedAt
      projectSetting
      mailSetting
    }
  }
`;
export const getFormDetail = /* GraphQL */ `
  query GetFormDetail($params: GetFormsDetailRequestDto) {
    getFormDetail(params: $params) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      relatedMasterForm
      settingAutoAdd
      sysField
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      itemList {
        id
        name
        isCheckbox
        isErrorValidate
        isEditName
        no
        hitNumber
        structure
      }
      defaultItemList {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        hasValue
        usingForms
        hitNumber
        relatedMasterForm
        settingAutoAdd
        sysField
        sharedFormList
        sharedFieldList
        shareRange
        shareOption
        viewPermission
        editPermission
        chatPermission
        createTicketForOwnerPermission
        additionalCreateTicketForOwnerPermission
        reviewFlag
        promptKnowledgeFormFlag
        isSynonym
        formCategories
        isUsingInCategories
        definitionVariant
      }
      promptKnowledgeFormFlag
      isSynonym
      formCategories
      isUsingInCategories
      definitionVariant
    }
  }
`;
export const getFormList = /* GraphQL */ `
  query GetFormList($params: GetFormsListRequestDto) {
    getFormList(params: $params) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      relatedMasterForm
      settingAutoAdd
      sysField
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      itemList {
        id
        name
        isCheckbox
        isErrorValidate
        isEditName
        no
        hitNumber
        structure
      }
      defaultItemList {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        hasValue
        usingForms
        hitNumber
        relatedMasterForm
        settingAutoAdd
        sysField
        sharedFormList
        sharedFieldList
        shareRange
        shareOption
        viewPermission
        editPermission
        chatPermission
        createTicketForOwnerPermission
        additionalCreateTicketForOwnerPermission
        reviewFlag
        promptKnowledgeFormFlag
        isSynonym
        formCategories
        isUsingInCategories
        definitionVariant
      }
      promptKnowledgeFormFlag
      isSynonym
      formCategories
      isUsingInCategories
      definitionVariant
    }
  }
`;
export const getFormFieldList = /* GraphQL */ `
  query GetFormFieldList($params: GetFormFieldListRequestDto) {
    getFormFieldList(params: $params) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      relatedMasterForm
      settingAutoAdd
      sysField
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      itemList {
        id
        name
        isCheckbox
        isErrorValidate
        isEditName
        no
        hitNumber
        structure
      }
      defaultItemList {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        hasValue
        usingForms
        hitNumber
        relatedMasterForm
        settingAutoAdd
        sysField
        sharedFormList
        sharedFieldList
        shareRange
        shareOption
        viewPermission
        editPermission
        chatPermission
        createTicketForOwnerPermission
        additionalCreateTicketForOwnerPermission
        reviewFlag
        promptKnowledgeFormFlag
        isSynonym
        formCategories
        isUsingInCategories
        definitionVariant
      }
      promptKnowledgeFormFlag
      isSynonym
      formCategories
      isUsingInCategories
      definitionVariant
    }
  }
`;
export const getViewDetail = /* GraphQL */ `
  query GetViewDetail($params: GetViewDetailRequestDto) {
    getViewDetail(params: $params) {
      id
      name
      affiliationOrganization
      affiliationProject
      affiliationView
      visibleRange
      visibleType
      structure
      viewType
      status
      order
      creator
      updator
      createdAt
      updatedAt
      definitionView
    }
  }
`;
export const getViewList = /* GraphQL */ `
  query GetViewList($params: GetViewListRequestDto) {
    getViewList(params: $params) {
      id
      name
      affiliationOrganization
      affiliationProject
      affiliationView
      visibleRange
      visibleType
      structure
      viewType
      status
      order
      creator
      updator
      createdAt
      updatedAt
      definitionView
    }
  }
`;
export const getChatThreadDetail = /* GraphQL */ `
  query GetChatThreadDetail($params: GetChatThreadDetailRequestDto) {
    getChatThreadDetail(params: $params) {
      id
      corporationId
      sharedRange
      editableRange
      ticketId
      threadType
      createdAt
      updatedAt
      creatorId
    }
  }
`;
export const getChatThreadList = /* GraphQL */ `
  query GetChatThreadList($params: GetChatThreadListRequestDto) {
    getChatThreadList(params: $params) {
      id
      corporationId
      sharedRange
      editableRange
      ticketId
      threadType
      createdAt
      updatedAt
      creatorId
    }
  }
`;
export const getChatPostList = /* GraphQL */ `
  query GetChatPostList($params: GetChatPostListRequestDto) {
    getChatPostList(params: $params) {
      id
      corporationId
      affiliationProject
      postType
      chatThreadId
      content
      delegateUserId
      fileUrl
      fileSize
      status
      footPrint {
        userList
      }
      history {
        content
        updatedAt
      }
      seq
      createdAt
      updatedAt
      creatorId
      mention
      reaction
      thumbnailInfo
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
      ticketId
    }
  }
`;
export const getChatPostDetail = /* GraphQL */ `
  query GetChatPostDetail($params: GetChatPostDetailRequestDto) {
    getChatPostDetail(params: $params) {
      id
      corporationId
      affiliationProject
      postType
      chatThreadId
      content
      delegateUserId
      fileUrl
      fileSize
      status
      footPrint {
        userList
      }
      history {
        content
        updatedAt
      }
      seq
      createdAt
      updatedAt
      creatorId
      mention
      reaction
      thumbnailInfo
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
      ticketId
    }
  }
`;
export const getExternalDeskProjectList = /* GraphQL */ `
  query GetExternalDeskProjectList(
    $params: GetExternalDeskProjectListRequestDto
  ) {
    getExternalDeskProjectList(params: $params) {
      id
      affiliationOrganization
      image
      name
      mail
      tel
      createdAt
      corporationData {
        id
        name
      }
    }
  }
`;
export const getExternalCustomerProjectList = /* GraphQL */ `
  query GetExternalCustomerProjectList(
    $params: GetExternalCustomerProjectListRequestDto
  ) {
    getExternalCustomerProjectList(params: $params) {
      id
      affiliationOrganization
      image
      name
      createdAt
      corporationData {
        id
        name
      }
    }
  }
`;
export const getNotificationDetail = /* GraphQL */ `
  query GetNotificationDetail($params: GetNotificationDetailRequestDto) {
    getNotificationDetail(params: $params) {
      id
      systemType
      affiliationOrganization
      affiliationProject
      notificationRange
      sourceObject
      objectType
      eventType
      sourceInfo
      footPrint
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const getNotificationList = /* GraphQL */ `
  query GetNotificationList($params: GetNotificationListRequestDto) {
    getNotificationList(params: $params) {
      id
      systemType
      affiliationOrganization
      affiliationProject
      notificationRange
      sourceObject
      objectType
      eventType
      sourceInfo
      footPrint
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const getTicketHelpDeskDrillDownSearch = /* GraphQL */ `
  query GetTicketHelpDeskDrillDownSearch(
    $params: GetTicketHelpDeskDrillDownSearchRequestDto
  ) {
    getTicketHelpDeskDrillDownSearch(params: $params) {
      data
    }
  }
`;
export const getLastMessage = /* GraphQL */ `
  query GetLastMessage($params: GetLastMessageRequestDto) {
    getLastMessage(params: $params) {
      id
      corporationId
      affiliationProject
      postType
      chatThreadId
      content
      delegateUserId
      fileUrl
      fileSize
      status
      seq
      createdAt
      updatedAt
      creatorId
      numberUnRead
      creatorInfo {
        image
        firstName
        middleName
        lastName
        languages
      }
    }
  }
`;
export const getTicketListDetail = /* GraphQL */ `
  query GetTicketListDetail($params: GetTicketListDetailRequestDto) {
    getTicketListDetail(params: $params) {
      id
      usage
      type
      affiliationOrganization
      affiliationProject
      customer
      additionalReadPermission
      readPermission
      additionalWritePermission
      writePermission
      createdAt
      updatedAt
      deadLine
      deletedAt
      creator
      updator
      personInCharge
      proxiedUser
      outline
      description
      memo
      attachment {
        fileUrl
        fileSize
        fileName
        createdAt
        thumbnailInfo
        idTicketFileKnowledge
        isPdf
        splitPdf
        isCreatingPageKnowledge
      }
      suffixOfAttachments
      liveOrArchived
      status
      knowledgeCanvas
      chatThreadWithCustomer
      chatThreadInProject
      collaborators
      customersCustomer
      sprint
      priority
      processingOrder
      projectOrigin
      projectTarget
      parent
      child
      ticketType
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      invitationFlag
      approveFlag
      publicType
      publicRange
      public
      titleLineNumber
      statusDetail {
        name
        structure
        currentStatus
        currentStatusField
      }
      statusItemList {
        id
        affiliationField
        affiliationOrganization
        affiliationProject
        name
        structure
        viewForCustomer
      }
      affiliationProjectInfo {
        name
        image
        shortName
        userNoticeImage
        corporationInfo {
          id
          active
          name
        }
      }
      lastMessage {
        userId
        chatPostId
      }
      lastMessageInfo {
        unreadMessageNumber
        message
        avatar
        chatThreadId
        userDetail
      }
      anonymousUserInfo {
        mailAddress
        reEmailAddress
        firstName
        lastName
        firstNameKana
        lastNameKana
        type
        isCustomerInvitationSettingOff
      }
      anonymousQaStatus
      personInChargeInfo {
        id
        avatar
        firstName
        lastName
        shortName
        picFieldPrivateStatus
        picFieldActive
        mail
        active
        fullName
      }
      creatorInfo {
        id
        avatar
        firstName
        lastName
        shortName
        picFieldPrivateStatus
        picFieldActive
        mail
        active
        fullName
      }
      isInternalTicket
      isExternalTicket
      isHideAttachment
      updatedForCustomer
      updatedForDesk
      settingTitle {
        text
        id
        isBreak
        sysField
      }
      promptFlag
      contentPagePdf
      totalToken
      formDefinition
      toListOfEmailTicket
    }
  }
`;
export const getCorporationContractDetail = /* GraphQL */ `
  query GetCorporationContractDetail(
    $params: GetCorporationContractDetailRequestDto
  ) {
    getCorporationContractDetail(params: $params) {
      status
      data
    }
  }
`;
export const getTicketKnowledgePrompt = /* GraphQL */ `
  query GetTicketKnowledgePrompt($params: GetTicketKnowledgePromptRequestDto) {
    getTicketKnowledgePrompt(params: $params) {
      status
      result
    }
  }
`;
export const getDataPublicRange = /* GraphQL */ `
  query GetDataPublicRange($params: GetDrillDownSearchRequestDto) {
    getDataPublicRange(params: $params) {
      data
    }
  }
`;
export const getTicketDetail = /* GraphQL */ `
  query GetTicketDetail($params: GetTicketDetailRequestDto!) {
    getTicketDetail(params: $params) {
      id
      ticketId
      dataType
      dataID
      dataLang
      dataValue
      usage
      type
      affiliationOrganization
      affiliationProject
      additionalCustomer
      customer
      additionalReadPermission
      readPermission
      additionalWritePermission
      writePermission
      createdAt
      updatedAt
      deadLine
      creator
      updator
      personInCharge
      proxiedUser
      outline
      description
      memo
      attachment {
        fileUrl
        fileSize
        fileName
        createdAt
        thumbnailInfo
        idTicketFileKnowledge
        isPdf
        splitPdf
        isCreatingPageKnowledge
      }
      suffixOfAttachments
      liveOrArchived
      status
      fields
      knowledgeCanvas
      chatThreadWithCustomer
      chatThreadInProject
      collaborators
      customersCustomer
      sprint
      priority
      processingOrder
      projectOrigin
      projectTarget
      parent
      children
      child
      ticketType
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      invitationFlag
      approveFlag
      approvePageLink {
        link
        expireTime
      }
      projectTargetName
      projectOriginName
      createrUserName
      updatorUserName
      statusDetail
      listTicketChild
      parentTicket
      proxiedUserName
      publicType
      publicRange
      public
      customerName
      inhouseCollaboratorTicketId
      inhouseCustomerTicketId
      externalCollaboratorTicketId
      externalCustomerTicketId
      formId
      isExternalTicket
      anonymousUserInfo {
        mailAddress
        reEmailAddress
        firstName
        lastName
        firstNameKana
        lastNameKana
        type
        isCustomerInvitationSettingOff
      }
      anonymousQaStatus
      personInChargeInfo {
        id
        avatar
        firstName
        lastName
        shortName
        fullName
        affiliationOrganizationInfo {
          id
          active
          name
          languages
        }
        active
      }
      updatedForCustomer
      updatedForDesk
      settingTitle {
        text
        id
        isBreak
        sysField
      }
      footPrint
      viewPermission
      additionalViewPermission
      editPermission
      additionalEditPermission
      chatPermission
      deletedAt
      activeB2BSupportList
      activeB2BCustomerList
      isDelete
      hasOneSideBlockedOrg
      hasOneSideDeletedOrg
      listBlockedOrg
      listDeletedOrg
      deskService
      knowledgeService
      taskManagementService
      publicService
      promptFlag
      contentPagePdf
      totalToken
      isSynonym
      errorMessage
      convertPDFOrg
      bookmarkInfo
      metaData
      deliverStatus
      deliverInfo
      listTicketEmailChild
      promptData
    }
  }
`;
export const getApiPublic = /* GraphQL */ `
  query GetApiPublic($params: String!) {
    getApiPublic(params: $params) {
      success
      payload
      errors
      queryResponse
    }
  }
`;
export const authenServicePublicApi = /* GraphQL */ `
  query AuthenServicePublicApi($params: String!) {
    authenServicePublicApi(params: $params) {
      success
      payload
      errors
      queryResponse
    }
  }
`;
export const checkViewTicketPermission = /* GraphQL */ `
  query CheckViewTicketPermission(
    $params: CheckViewTicketPermissionRequestDto
  ) {
    checkViewTicketPermission(params: $params) {
      success
      payload
      errors
    }
  }
`;
export const getProductManagement = /* GraphQL */ `
  query GetProductManagement($params: GetProductManagementRequestDto) {
    getProductManagement(params: $params) {
      status
      listProduct {
        id
        productId
        name
        order
        status
        updatedAt
        deletedAt
        isExistOnSaleEdition
        moduleSetting
      }
      listEdition {
        id
        productId
        name
        order
        status
        editionId
        isTrial
        listVersion
        deletedAt
        updatedAt
      }
      productDetail {
        id
        productId
        name
        order
        status
        updatedAt
        deletedAt
        isExistOnSaleEdition
        moduleSetting
      }
      versionDetail
      errorMessage
      increaseRate
    }
  }
`;
export const getTicketListByQueryChatPost = /* GraphQL */ `
  query GetTicketListByQueryChatPost(
    $params: GetTicketListByQueryChatPostRequestDto
  ) {
    getTicketListByQueryChatPost(params: $params) {
      status
      ticketList
    }
  }
`;
export const publicGetKnowledgeList = /* GraphQL */ `
  query PublicGetKnowledgeList($params: String!) {
    publicGetKnowledgeList(params: $params) {
      success
      payload
      errors
      queryResponse
    }
  }
`;
export const executeQueryEls = /* GraphQL */ `
  query ExecuteQueryEls($params: String!) {
    executeQueryEls(params: $params) {
      success
      payload
      errors
      queryResponse
    }
  }
`;
export const bookmarkGetAPI = /* GraphQL */ `
  query BookmarkGetAPI($params: BookmarkGetAPIRequestDto!) {
    bookmarkGetAPI(params: $params) {
      data
    }
  }
`;
export const AiAutoLogGet = /* GraphQL */ `
  query AiAutoLogGet($params: AiAutoLogGetRequestDto!) {
    AiAutoLogGet(params: $params) {
      success
      payload
      error
    }
  }
`;
export const getAIChatPost = /* GraphQL */ `
  query GetAIChatPost($id: ID!) {
    getAIChatPost(id: $id) {
      id
      corporationId
      affiliationProject
      postType
      chatThreadId
      role
      content
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
    }
  }
`;
export const listAIChatPosts = /* GraphQL */ `
  query ListAIChatPosts(
    $filter: ModelAIChatPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIChatPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        corporationId
        affiliationProject
        postType
        chatThreadId
        role
        content
        clientUUId
        seq
        createdAt
        updatedAt
        creatorId
      }
      nextToken
    }
  }
`;
export const queryAIChatPostGSI1 = /* GraphQL */ `
  query QueryAIChatPostGSI1(
    $chatThreadId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIChatPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAIChatPostGSI1(
      chatThreadId: $chatThreadId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporationId
        affiliationProject
        postType
        chatThreadId
        role
        content
        clientUUId
        seq
        createdAt
        updatedAt
        creatorId
      }
      nextToken
    }
  }
`;
export const getAIChatThread = /* GraphQL */ `
  query GetAIChatThread($id: ID!) {
    getAIChatThread(id: $id) {
      id
      corporationId
      affiliationProject
      chatThreadId
      optionSearch {
        methodSearch
        attributeSearch
        keywordList
        fromSearchDate
        toSearchDate
        formatSearch
        sortType
      }
      drillDownSearch {
        ticketType
        creator
        updator
        proxiedUser
        suffixOfAttachments
        form
        tagFields {
          fieldId
          fieldItemId
        }
        statusFields {
          fieldId
          fieldItemId
        }
        publicRange
        sharedBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
        privateBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
      }
      clientUUId
      ticketId
      chatThreadType
      status
      metaData {
        paramsReplayS3Path
      }
      aiLogMetaData
      createdAt
      updatedAt
      startTime
      endTime
      creatorId
      updatorId
    }
  }
`;
export const listAIChatThreads = /* GraphQL */ `
  query ListAIChatThreads(
    $filter: ModelAIChatThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIChatThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        corporationId
        affiliationProject
        chatThreadId
        optionSearch {
          methodSearch
          attributeSearch
          keywordList
          fromSearchDate
          toSearchDate
          formatSearch
          sortType
        }
        drillDownSearch {
          ticketType
          creator
          updator
          proxiedUser
          suffixOfAttachments
          form
          tagFields {
            fieldId
            fieldItemId
          }
          statusFields {
            fieldId
            fieldItemId
          }
          publicRange
          sharedBookmark {
            bookmarkId
            userSelectId
            projectSelectId
          }
          privateBookmark {
            bookmarkId
            userSelectId
            projectSelectId
          }
        }
        clientUUId
        ticketId
        chatThreadType
        status
        metaData {
          paramsReplayS3Path
        }
        aiLogMetaData
        createdAt
        updatedAt
        startTime
        endTime
        creatorId
        updatorId
      }
      nextToken
    }
  }
`;
export const queryAIChatThreadGSI1 = /* GraphQL */ `
  query QueryAIChatThreadGSI1(
    $creatorId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIChatThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAIChatThreadGSI1(
      creatorId: $creatorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporationId
        affiliationProject
        chatThreadId
        optionSearch {
          methodSearch
          attributeSearch
          keywordList
          fromSearchDate
          toSearchDate
          formatSearch
          sortType
        }
        drillDownSearch {
          ticketType
          creator
          updator
          proxiedUser
          suffixOfAttachments
          form
          tagFields {
            fieldId
            fieldItemId
          }
          statusFields {
            fieldId
            fieldItemId
          }
          publicRange
          sharedBookmark {
            bookmarkId
            userSelectId
            projectSelectId
          }
          privateBookmark {
            bookmarkId
            userSelectId
            projectSelectId
          }
        }
        clientUUId
        ticketId
        chatThreadType
        status
        metaData {
          paramsReplayS3Path
        }
        aiLogMetaData
        createdAt
        updatedAt
        startTime
        endTime
        creatorId
        updatorId
      }
      nextToken
    }
  }
`;
export const queryAIChatThreadGSI2 = /* GraphQL */ `
  query QueryAIChatThreadGSI2(
    $ticketId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIChatThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAIChatThreadGSI2(
      ticketId: $ticketId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporationId
        affiliationProject
        chatThreadId
        optionSearch {
          methodSearch
          attributeSearch
          keywordList
          fromSearchDate
          toSearchDate
          formatSearch
          sortType
        }
        drillDownSearch {
          ticketType
          creator
          updator
          proxiedUser
          suffixOfAttachments
          form
          tagFields {
            fieldId
            fieldItemId
          }
          statusFields {
            fieldId
            fieldItemId
          }
          publicRange
          sharedBookmark {
            bookmarkId
            userSelectId
            projectSelectId
          }
          privateBookmark {
            bookmarkId
            userSelectId
            projectSelectId
          }
        }
        clientUUId
        ticketId
        chatThreadType
        status
        metaData {
          paramsReplayS3Path
        }
        aiLogMetaData
        createdAt
        updatedAt
        startTime
        endTime
        creatorId
        updatorId
      }
      nextToken
    }
  }
`;
export const getAttachFile = /* GraphQL */ `
  query GetAttachFile($id: ID!) {
    getAttachFile(id: $id) {
      id
      relatedId
      affiliationOrganization
      affiliationProject
      originId
      s3Path
      fileInfo
      thumbnailInfo
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
export const listAttachFiles = /* GraphQL */ `
  query ListAttachFiles(
    $filter: ModelAttachFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        relatedId
        affiliationOrganization
        affiliationProject
        originId
        s3Path
        fileInfo
        thumbnailInfo
        creator
        updator
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryAttachFileGSI1 = /* GraphQL */ `
  query QueryAttachFileGSI1(
    $affiliationOrganization: String
    $affiliationProjectCreatedAt: ModelAttachFileAttachFileGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttachFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAttachFileGSI1(
      affiliationOrganization: $affiliationOrganization
      affiliationProjectCreatedAt: $affiliationProjectCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relatedId
        affiliationOrganization
        affiliationProject
        originId
        s3Path
        fileInfo
        thumbnailInfo
        creator
        updator
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryAttachFileGSI2 = /* GraphQL */ `
  query QueryAttachFileGSI2(
    $relatedId: String
    $s3PathCreatedAt: ModelAttachFileAttachFileGSI2CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttachFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAttachFileGSI2(
      relatedId: $relatedId
      s3PathCreatedAt: $s3PathCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relatedId
        affiliationOrganization
        affiliationProject
        originId
        s3Path
        fileInfo
        thumbnailInfo
        creator
        updator
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookmark = /* GraphQL */ `
  query GetBookmark($id: ID!) {
    getBookmark(id: $id) {
      id
      affiliationOrganization
      affiliationProject
      name
      definition
      shareRange
      defaultBookmark
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const listBookmarks = /* GraphQL */ `
  query ListBookmarks(
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        affiliationOrganization
        affiliationProject
        name
        definition
        shareRange
        defaultBookmark
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryBookmarkGSI1 = /* GraphQL */ `
  query QueryBookmarkGSI1(
    $affiliationProject: String
    $shareRangeDefinitionCreatedAt: ModelBookmarkBookmarkGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBookmarkGSI1(
      affiliationProject: $affiliationProject
      shareRangeDefinitionCreatedAt: $shareRangeDefinitionCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliationOrganization
        affiliationProject
        name
        definition
        shareRange
        defaultBookmark
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryBookmarkGSI2 = /* GraphQL */ `
  query QueryBookmarkGSI2(
    $affiliationProject: String
    $defaultBookmarkCreatedAt: ModelBookmarkBookmarkGSI2CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBookmarkGSI2(
      affiliationProject: $affiliationProject
      defaultBookmarkCreatedAt: $defaultBookmarkCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliationOrganization
        affiliationProject
        name
        definition
        shareRange
        defaultBookmark
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatPost = /* GraphQL */ `
  query GetChatPost($id: ID!) {
    getChatPost(id: $id) {
      id
      ticketAffiliationOrganization
      ticketAffiliationProject
      corporationId
      affiliationProject
      postType
      chatThreadId
      content
      delegateUserId
      fileUrl
      fileSize
      thumbnailInfo
      status
      footPrint {
        userList
      }
      history {
        content
        updatedAt
      }
      mention
      reaction
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
      ticketId
      role
      emailId
      deliverStatus
      deliverAt
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const listChatPosts = /* GraphQL */ `
  query ListChatPosts(
    $filter: ModelChatPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ticketAffiliationOrganization
        ticketAffiliationProject
        corporationId
        affiliationProject
        postType
        chatThreadId
        content
        delegateUserId
        fileUrl
        fileSize
        thumbnailInfo
        status
        footPrint {
          userList
        }
        history {
          content
          updatedAt
        }
        mention
        reaction
        clientUUId
        seq
        createdAt
        updatedAt
        creatorId
        ticketId
        role
        emailId
        deliverStatus
        deliverAt
        deliverInfo {
          status
          deliverAt
          sender
          __typename
        }
      }
      nextToken
    }
  }
`;
export const listPostByChatThreadId = /* GraphQL */ `
  query ListPostByChatThreadId(
    $chatThreadId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostByChatThreadId(
      chatThreadId: $chatThreadId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketAffiliationOrganization
        ticketAffiliationProject
        corporationId
        affiliationProject
        postType
        chatThreadId
        content
        delegateUserId
        fileUrl
        fileSize
        thumbnailInfo
        status
        footPrint {
          userList
        }
        history {
          content
          updatedAt
        }
        mention
        reaction
        clientUUId
        seq
        createdAt
        updatedAt
        creatorId
        ticketId
        role
        emailId
        deliverStatus
        deliverAt
        deliverInfo {
          status
          deliverAt
          sender
          __typename
        }
      }
      nextToken
    }
  }
`;
export const getChatThread = /* GraphQL */ `
  query GetChatThread($id: ID!) {
    getChatThread(id: $id) {
      id
      corporationId
      sharedRange
      editableRange
      ticketId
      threadType
      createdAt
      updatedAt
      creatorId
      affiliationProject
      chatThreadId
      clientUUId
    }
  }
`;
export const listChatThreads = /* GraphQL */ `
  query ListChatThreads(
    $filter: ModelChatThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        corporationId
        sharedRange
        editableRange
        ticketId
        threadType
        createdAt
        updatedAt
        creatorId
        affiliationProject
        chatThreadId
        clientUUId
      }
      nextToken
    }
  }
`;
export const listChatThreadByTicketId = /* GraphQL */ `
  query ListChatThreadByTicketId(
    $ticketId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatThreadByTicketId(
      ticketId: $ticketId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporationId
        sharedRange
        editableRange
        ticketId
        threadType
        createdAt
        updatedAt
        creatorId
        affiliationProject
        chatThreadId
        clientUUId
      }
      nextToken
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      affiliationOrganization
      productId
      editionId
      versionId
      kind
      paymentStatus
      paymentMethod
      paymentCycle
      currentPaymentCycle
      paymentDate
      price
      contractStatus
      additionalUpgradeSetting
      additionalPrivateSetting
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      metaData
      trialDays
      startDate
      billStartDate
      billEndDate
      endTrialDate
      createdAt
      updatedAt
      creator
      updator
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        affiliationOrganization
        productId
        editionId
        versionId
        kind
        paymentStatus
        paymentMethod
        paymentCycle
        currentPaymentCycle
        paymentDate
        price
        contractStatus
        additionalUpgradeSetting
        additionalPrivateSetting
        limitValue {
          maxNumOfGeneralUser
          numOfGeneralUser
          maxNumOfAgent
          numOfAgent
          maxNumOfGuestUser
          numOfGuestUser
          maxNumOfCustomer
          numOfCustomer
          maxNumOfGeneralProject
          numOfGeneralProject
          maxNumOfGuestProject
          numOfGuestProject
          maxNumOfGuestDesk
          numOfGuestDesk
          numOfTicket
          maxNumOfTicket
          numOfStorage
          maxNumOfStorage
          numOfInternalUser
          numOfB2CCustomer
          numOfProjectDeskOn
          numOfProjectPublicOn
          numOfTicketKnowledge
          internalQACounterSettings
          publicWebForm
          emailResponseFunction
          knowledgeBot
          synonymSearch
          batchCommandService
          convertPDF
          chatAI
          usingChatAI
          lincenseKeyPermission
          lincenseKeySetting
          lincenseKeyValue
          chatGPTModel
          knnValue
          minScore
          aiAuto
          systemPrompt
          interatorSystemPrompt
          usingAIAuto
          advancedOptionSettings
          logAnalysis
          ipRestriction
          maxNumOfTicketKnowledge
          maxNumberOfFormat
          maxNumberOfQACounters
          maxNumberOfSupportedCounters
          maxNumOfInternalUser
          maxNumOfB2CCustomer
          numberOfFormat
          numberOfQACounters
          numberOfSupportedCounters
          storageVolume
          generalUserFee
          basicCharge
          taskManagement
          vectorTicketDataStatus
          deskService
          knowledgeService
          publicService
          publicServiceDeskSettings
          solutionsDeskOfKnowledge
          publicKnowledgeSite
          numOfUserInOrg
          numOfPartnerId
        }
        metaData
        trialDays
        startDate
        billStartDate
        billEndDate
        endTrialDate
        createdAt
        updatedAt
        creator
        updator
      }
      nextToken
    }
  }
`;
export const queryContractGSI1 = /* GraphQL */ `
  query QueryContractGSI1(
    $affiliationOrganization: String
    $kind: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryContractGSI1(
      affiliationOrganization: $affiliationOrganization
      kind: $kind
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliationOrganization
        productId
        editionId
        versionId
        kind
        paymentStatus
        paymentMethod
        paymentCycle
        currentPaymentCycle
        paymentDate
        price
        contractStatus
        additionalUpgradeSetting
        additionalPrivateSetting
        limitValue {
          maxNumOfGeneralUser
          numOfGeneralUser
          maxNumOfAgent
          numOfAgent
          maxNumOfGuestUser
          numOfGuestUser
          maxNumOfCustomer
          numOfCustomer
          maxNumOfGeneralProject
          numOfGeneralProject
          maxNumOfGuestProject
          numOfGuestProject
          maxNumOfGuestDesk
          numOfGuestDesk
          numOfTicket
          maxNumOfTicket
          numOfStorage
          maxNumOfStorage
          numOfInternalUser
          numOfB2CCustomer
          numOfProjectDeskOn
          numOfProjectPublicOn
          numOfTicketKnowledge
          internalQACounterSettings
          publicWebForm
          emailResponseFunction
          knowledgeBot
          synonymSearch
          batchCommandService
          convertPDF
          chatAI
          usingChatAI
          lincenseKeyPermission
          lincenseKeySetting
          lincenseKeyValue
          chatGPTModel
          knnValue
          minScore
          aiAuto
          systemPrompt
          interatorSystemPrompt
          usingAIAuto
          advancedOptionSettings
          logAnalysis
          ipRestriction
          maxNumOfTicketKnowledge
          maxNumberOfFormat
          maxNumberOfQACounters
          maxNumberOfSupportedCounters
          maxNumOfInternalUser
          maxNumOfB2CCustomer
          numberOfFormat
          numberOfQACounters
          numberOfSupportedCounters
          storageVolume
          generalUserFee
          basicCharge
          taskManagement
          vectorTicketDataStatus
          deskService
          knowledgeService
          publicService
          publicServiceDeskSettings
          solutionsDeskOfKnowledge
          publicKnowledgeSite
          numOfUserInOrg
          numOfPartnerId
        }
        metaData
        trialDays
        startDate
        billStartDate
        billEndDate
        endTrialDate
        createdAt
        updatedAt
        creator
        updator
      }
      nextToken
    }
  }
`;
export const getCorporation = /* GraphQL */ `
  query GetCorporation($id: ID!) {
    getCorporation(id: $id) {
      id
      usage
      affiliationOrganization
      image
      logoFileName
      active
      name
      languages
      autoTranslation
      organizationDomain
      contractType
      contractDate
      lastDayOfContract
      creditCard
      ZIPcode
      address
      mail
      tel
      departmentName
      activatedFunctions
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      inviter
      inviterOrganizationDomain
      corporationInvited
      creator
      createdAt
      updator
      updatedAt
      rootFlag
      owner
      deskService
      knowledgeService
      taskManagementService
      publicService
      metaData
      versionId
      contractId
      contractStatus
      corporationSetting
    }
  }
`;
export const listCorporations = /* GraphQL */ `
  query ListCorporations(
    $filter: ModelCorporationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usage
        affiliationOrganization
        image
        logoFileName
        active
        name
        languages
        autoTranslation
        organizationDomain
        contractType
        contractDate
        lastDayOfContract
        creditCard
        ZIPcode
        address
        mail
        tel
        departmentName
        activatedFunctions
        limitValue {
          maxNumOfGeneralUser
          numOfGeneralUser
          maxNumOfAgent
          numOfAgent
          maxNumOfGuestUser
          numOfGuestUser
          maxNumOfCustomer
          numOfCustomer
          maxNumOfGeneralProject
          numOfGeneralProject
          maxNumOfGuestProject
          numOfGuestProject
          maxNumOfGuestDesk
          numOfGuestDesk
          numOfTicket
          maxNumOfTicket
          numOfStorage
          maxNumOfStorage
          numOfInternalUser
          numOfB2CCustomer
          numOfProjectDeskOn
          numOfProjectPublicOn
          numOfTicketKnowledge
          internalQACounterSettings
          publicWebForm
          emailResponseFunction
          knowledgeBot
          synonymSearch
          batchCommandService
          convertPDF
          chatAI
          usingChatAI
          lincenseKeyPermission
          lincenseKeySetting
          lincenseKeyValue
          chatGPTModel
          knnValue
          minScore
          aiAuto
          systemPrompt
          interatorSystemPrompt
          usingAIAuto
          advancedOptionSettings
          logAnalysis
          ipRestriction
          maxNumOfTicketKnowledge
          maxNumberOfFormat
          maxNumberOfQACounters
          maxNumberOfSupportedCounters
          maxNumOfInternalUser
          maxNumOfB2CCustomer
          numberOfFormat
          numberOfQACounters
          numberOfSupportedCounters
          storageVolume
          generalUserFee
          basicCharge
          taskManagement
          vectorTicketDataStatus
          deskService
          knowledgeService
          publicService
          publicServiceDeskSettings
          solutionsDeskOfKnowledge
          publicKnowledgeSite
          numOfUserInOrg
          numOfPartnerId
        }
        inviter
        inviterOrganizationDomain
        corporationInvited
        creator
        createdAt
        updator
        updatedAt
        rootFlag
        owner
        deskService
        knowledgeService
        taskManagementService
        publicService
        metaData
        versionId
        contractId
        contractStatus
        corporationSetting
      }
      nextToken
    }
  }
`;
export const listCorporationByOrganizationDomain = /* GraphQL */ `
  query ListCorporationByOrganizationDomain(
    $organizationDomain: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorporationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporationByOrganizationDomain(
      organizationDomain: $organizationDomain
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        affiliationOrganization
        image
        logoFileName
        active
        name
        languages
        autoTranslation
        organizationDomain
        contractType
        contractDate
        lastDayOfContract
        creditCard
        ZIPcode
        address
        mail
        tel
        departmentName
        activatedFunctions
        limitValue {
          maxNumOfGeneralUser
          numOfGeneralUser
          maxNumOfAgent
          numOfAgent
          maxNumOfGuestUser
          numOfGuestUser
          maxNumOfCustomer
          numOfCustomer
          maxNumOfGeneralProject
          numOfGeneralProject
          maxNumOfGuestProject
          numOfGuestProject
          maxNumOfGuestDesk
          numOfGuestDesk
          numOfTicket
          maxNumOfTicket
          numOfStorage
          maxNumOfStorage
          numOfInternalUser
          numOfB2CCustomer
          numOfProjectDeskOn
          numOfProjectPublicOn
          numOfTicketKnowledge
          internalQACounterSettings
          publicWebForm
          emailResponseFunction
          knowledgeBot
          synonymSearch
          batchCommandService
          convertPDF
          chatAI
          usingChatAI
          lincenseKeyPermission
          lincenseKeySetting
          lincenseKeyValue
          chatGPTModel
          knnValue
          minScore
          aiAuto
          systemPrompt
          interatorSystemPrompt
          usingAIAuto
          advancedOptionSettings
          logAnalysis
          ipRestriction
          maxNumOfTicketKnowledge
          maxNumberOfFormat
          maxNumberOfQACounters
          maxNumberOfSupportedCounters
          maxNumOfInternalUser
          maxNumOfB2CCustomer
          numberOfFormat
          numberOfQACounters
          numberOfSupportedCounters
          storageVolume
          generalUserFee
          basicCharge
          taskManagement
          vectorTicketDataStatus
          deskService
          knowledgeService
          publicService
          publicServiceDeskSettings
          solutionsDeskOfKnowledge
          publicKnowledgeSite
          numOfUserInOrg
          numOfPartnerId
        }
        inviter
        inviterOrganizationDomain
        corporationInvited
        creator
        createdAt
        updator
        updatedAt
        rootFlag
        owner
        deskService
        knowledgeService
        taskManagementService
        publicService
        metaData
        versionId
        contractId
        contractStatus
        corporationSetting
      }
      nextToken
    }
  }
`;
export const getForms = /* GraphQL */ `
  query GetForms($id: ID!) {
    getForms(id: $id) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      sysField
      relatedMasterForm
      settingAutoAdd
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      usingTicket
      usedProject
      promptKnowledgeFormFlag
      isSynonym
      definitionVariant
    }
  }
`;
export const listFormss = /* GraphQL */ `
  query ListFormss(
    $filter: ModelFormsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        hasValue
        usingForms
        hitNumber
        sysField
        relatedMasterForm
        settingAutoAdd
        sharedFormList
        sharedFieldList
        shareRange
        shareOption
        viewPermission
        editPermission
        chatPermission
        createTicketForOwnerPermission
        additionalCreateTicketForOwnerPermission
        reviewFlag
        usingTicket
        usedProject
        promptKnowledgeFormFlag
        isSynonym
        definitionVariant
      }
      nextToken
    }
  }
`;
export const queryFormGSI1 = /* GraphQL */ `
  query QueryFormGSI1(
    $usage: String
    $usageAffiliationOrganizationAffiliationFieldCreatedAt: ModelFormsFormGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryFormGSI1(
      usage: $usage
      usageAffiliationOrganizationAffiliationFieldCreatedAt: $usageAffiliationOrganizationAffiliationFieldCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        hasValue
        usingForms
        hitNumber
        sysField
        relatedMasterForm
        settingAutoAdd
        sharedFormList
        sharedFieldList
        shareRange
        shareOption
        viewPermission
        editPermission
        chatPermission
        createTicketForOwnerPermission
        additionalCreateTicketForOwnerPermission
        reviewFlag
        usingTicket
        usedProject
        promptKnowledgeFormFlag
        isSynonym
        definitionVariant
      }
      nextToken
    }
  }
`;
export const queryFormGSI2 = /* GraphQL */ `
  query QueryFormGSI2(
    $relatedMasterForm: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFormsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryFormGSI2(
      relatedMasterForm: $relatedMasterForm
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        hasValue
        usingForms
        hitNumber
        sysField
        relatedMasterForm
        settingAutoAdd
        sharedFormList
        sharedFieldList
        shareRange
        shareOption
        viewPermission
        editPermission
        chatPermission
        createTicketForOwnerPermission
        additionalCreateTicketForOwnerPermission
        reviewFlag
        usingTicket
        usedProject
        promptKnowledgeFormFlag
        isSynonym
        definitionVariant
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      systemType
      affiliationOrganization
      affiliationProject
      notificationRange
      sourceObject
      objectType
      eventType
      sourceInfo
      footPrint
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        systemType
        affiliationOrganization
        affiliationProject
        notificationRange
        sourceObject
        objectType
        eventType
        sourceInfo
        footPrint
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryNotificationGSI1 = /* GraphQL */ `
  query QueryNotificationGSI1(
    $notificationRange: String
    $sourceObjectCreatedAt: ModelNotificationNotificationGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryNotificationGSI1(
      notificationRange: $notificationRange
      sourceObjectCreatedAt: $sourceObjectCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemType
        affiliationOrganization
        affiliationProject
        notificationRange
        sourceObject
        objectType
        eventType
        sourceInfo
        footPrint
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      productId
      editionId
      name
      order
      metaData
      increaseRate
      usage
      status
      isTrial
      moduleSetting
      creator
      createdAt
      updator
      aiBasicPrompt
      updatedAt
      deletedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        editionId
        name
        order
        metaData
        increaseRate
        usage
        status
        isTrial
        moduleSetting
        creator
        createdAt
        updator
        aiBasicPrompt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const queryProductGSI1 = /* GraphQL */ `
  query QueryProductGSI1(
    $usage: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProductGSI1(
      usage: $usage
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        editionId
        name
        order
        metaData
        increaseRate
        usage
        status
        isTrial
        moduleSetting
        creator
        createdAt
        updator
        aiBasicPrompt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const queryProductGSI2 = /* GraphQL */ `
  query QueryProductGSI2(
    $productId: ID
    $editionIdUsageCreatedAt: ModelProductProductGSI2CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProductGSI2(
      productId: $productId
      editionIdUsageCreatedAt: $editionIdUsageCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        editionId
        name
        order
        metaData
        increaseRate
        usage
        status
        isTrial
        moduleSetting
        creator
        createdAt
        updator
        aiBasicPrompt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      projectId
      usage
      affiliationOrganization
      image
      active
      name
      languages
      autoTranslation
      id2
      privilege
      type
      mail
      tel
      projectInvite
      projectInvited
      iframeSetting
      chatbotSetting
      isDesk
      isPublic
      isGuest
      publicKnowledgeSiteSetting
      projectAlias
      projectSetting
      chatBotCategorySetting
      mailSetting
      userLogCache
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectId
        usage
        affiliationOrganization
        image
        active
        name
        languages
        autoTranslation
        id2
        privilege
        type
        mail
        tel
        projectInvite
        projectInvited
        iframeSetting
        chatbotSetting
        isDesk
        isPublic
        isGuest
        publicKnowledgeSiteSetting
        projectAlias
        projectSetting
        chatBotCategorySetting
        mailSetting
        userLogCache
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryProjectGSI1 = /* GraphQL */ `
  query QueryProjectGSI1(
    $affiliationOrganization: String
    $typeActive: ModelProjectProjectGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProjectGSI1(
      affiliationOrganization: $affiliationOrganization
      typeActive: $typeActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        usage
        affiliationOrganization
        image
        active
        name
        languages
        autoTranslation
        id2
        privilege
        type
        mail
        tel
        projectInvite
        projectInvited
        iframeSetting
        chatbotSetting
        isDesk
        isPublic
        isGuest
        publicKnowledgeSiteSetting
        projectAlias
        projectSetting
        chatBotCategorySetting
        mailSetting
        userLogCache
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryProjectGSI2 = /* GraphQL */ `
  query QueryProjectGSI2(
    $id2: ID
    $typeActive: ModelProjectProjectGSI2CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProjectGSI2(
      id2: $id2
      typeActive: $typeActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        usage
        affiliationOrganization
        image
        active
        name
        languages
        autoTranslation
        id2
        privilege
        type
        mail
        tel
        projectInvite
        projectInvited
        iframeSetting
        chatbotSetting
        isDesk
        isPublic
        isGuest
        publicKnowledgeSiteSetting
        projectAlias
        projectSetting
        chatBotCategorySetting
        mailSetting
        userLogCache
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryProjectGSI3 = /* GraphQL */ `
  query QueryProjectGSI3(
    $projectId: ID
    $typeActive: ModelProjectProjectGSI3CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProjectGSI3(
      projectId: $projectId
      typeActive: $typeActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        usage
        affiliationOrganization
        image
        active
        name
        languages
        autoTranslation
        id2
        privilege
        type
        mail
        tel
        projectInvite
        projectInvited
        iframeSetting
        chatbotSetting
        isDesk
        isPublic
        isGuest
        publicKnowledgeSiteSetting
        projectAlias
        projectSetting
        chatBotCategorySetting
        mailSetting
        userLogCache
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQueue = /* GraphQL */ `
  query GetQueue($id: ID!) {
    getQueue(id: $id) {
      id
      usage
      type
      status
      retry
      info
      planTime
      executeTime
      createdAt
      updatedAt
    }
  }
`;
export const listQueues = /* GraphQL */ `
  query ListQueues(
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usage
        type
        status
        retry
        info
        planTime
        executeTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryQueueGSI1 = /* GraphQL */ `
  query QueryQueueGSI1(
    $usage: Int
    $statusTypeCreatedAt: ModelQueueQueueGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryQueueGSI1(
      usage: $usage
      statusTypeCreatedAt: $statusTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        type
        status
        retry
        info
        planTime
        executeTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      usage
      type
      status
      retry
      info
      taskId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usage
        type
        status
        retry
        info
        taskId
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryTaskGSI1 = /* GraphQL */ `
  query QueryTaskGSI1(
    $usage: Int
    $statusTypeCreatedAt: ModelTaskTaskGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTaskGSI1(
      usage: $usage
      statusTypeCreatedAt: $statusTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        type
        status
        retry
        info
        taskId
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryTaskGSI2 = /* GraphQL */ `
  query QueryTaskGSI2(
    $type: String
    $typeTaskIdCreatedAt: ModelTaskTaskGSI2CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTaskGSI2(
      type: $type
      typeTaskIdCreatedAt: $typeTaskIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        type
        status
        retry
        info
        taskId
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      ticketId
      dataType
      dataID
      dataLang
      dataValue
      usage
      formId
      affiliationOrganization
      affiliationProject
      customer
      readPermission
      additionalReadPermission
      writePermission
      additionalWritePermission
      createdAt
      updatedAt
      creator
      updator
      proxiedUser
      proxyCreator
      liveOrArchived
      chatThreadWithCustomer
      chatThreadInProject
      inhouseCollaboratorTicketId
      inhouseCustomerTicketId
      externalCollaboratorTicketId
      externalCustomerTicketId
      public
      customersCustomer
      parent
      children
      projectOrigin
      projectTarget
      invitationFlag
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      approveFlag
      bookmark
      ticketType
      deadLine
      personInCharge
      status
      sprint
      priority
      processingOrder
      approvePageLink {
        link
        expireTime
      }
      publicType
      publicRange
      lastMessage
      anonymousUserInfo
      anonymousQaStatus
      updatedForCustomer
      updatedForDesk
      footPrint
      viewPermission
      additionalViewPermission
      editPermission
      additionalEditPermission
      chatPermission
      deletedAt
      timeVectorInfo
      promptFlag
      contentPagePdf
      totalToken
      isSynonym
      metaData
      promptData
      deliverStatus
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ticketId
        dataType
        dataID
        dataLang
        dataValue
        usage
        formId
        affiliationOrganization
        affiliationProject
        customer
        readPermission
        additionalReadPermission
        writePermission
        additionalWritePermission
        createdAt
        updatedAt
        creator
        updator
        proxiedUser
        proxyCreator
        liveOrArchived
        chatThreadWithCustomer
        chatThreadInProject
        inhouseCollaboratorTicketId
        inhouseCustomerTicketId
        externalCollaboratorTicketId
        externalCustomerTicketId
        public
        customersCustomer
        parent
        children
        projectOrigin
        projectTarget
        invitationFlag
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        approveFlag
        bookmark
        ticketType
        deadLine
        personInCharge
        status
        sprint
        priority
        processingOrder
        approvePageLink {
          link
          expireTime
        }
        publicType
        publicRange
        lastMessage
        anonymousUserInfo
        anonymousQaStatus
        updatedForCustomer
        updatedForDesk
        footPrint
        viewPermission
        additionalViewPermission
        editPermission
        additionalEditPermission
        chatPermission
        deletedAt
        timeVectorInfo
        promptFlag
        contentPagePdf
        totalToken
        isSynonym
        metaData
        promptData
        deliverStatus
        deliverInfo {
          status
          deliverAt
          sender
          __typename
        }
      }
      nextToken
    }
  }
`;
export const queryTicketGSI1 = /* GraphQL */ `
  query QueryTicketGSI1(
    $usage: String
    $usageAffiliationOrganizationAffiliationProjectReadPermissionLiveOrArchivedCreatedAt: ModelTicketTicketGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTicketGSI1(
      usage: $usage
      usageAffiliationOrganizationAffiliationProjectReadPermissionLiveOrArchivedCreatedAt: $usageAffiliationOrganizationAffiliationProjectReadPermissionLiveOrArchivedCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketId
        dataType
        dataID
        dataLang
        dataValue
        usage
        formId
        affiliationOrganization
        affiliationProject
        customer
        readPermission
        additionalReadPermission
        writePermission
        additionalWritePermission
        createdAt
        updatedAt
        creator
        updator
        proxiedUser
        proxyCreator
        liveOrArchived
        chatThreadWithCustomer
        chatThreadInProject
        inhouseCollaboratorTicketId
        inhouseCustomerTicketId
        externalCollaboratorTicketId
        externalCustomerTicketId
        public
        customersCustomer
        parent
        children
        projectOrigin
        projectTarget
        invitationFlag
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        approveFlag
        bookmark
        ticketType
        deadLine
        personInCharge
        status
        sprint
        priority
        processingOrder
        approvePageLink {
          link
          expireTime
        }
        publicType
        publicRange
        lastMessage
        anonymousUserInfo
        anonymousQaStatus
        updatedForCustomer
        updatedForDesk
        footPrint
        viewPermission
        additionalViewPermission
        editPermission
        additionalEditPermission
        chatPermission
        deletedAt
        timeVectorInfo
        promptFlag
        contentPagePdf
        totalToken
        isSynonym
        metaData
        promptData
        deliverStatus
        deliverInfo {
          status
          deliverAt
          sender
          __typename
        }
      }
      nextToken
    }
  }
`;
export const queryTicketGSI2 = /* GraphQL */ `
  query QueryTicketGSI2(
    $customer: ID
    $liveOrArchived: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTicketGSI2(
      customer: $customer
      liveOrArchived: $liveOrArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketId
        dataType
        dataID
        dataLang
        dataValue
        usage
        formId
        affiliationOrganization
        affiliationProject
        customer
        readPermission
        additionalReadPermission
        writePermission
        additionalWritePermission
        createdAt
        updatedAt
        creator
        updator
        proxiedUser
        proxyCreator
        liveOrArchived
        chatThreadWithCustomer
        chatThreadInProject
        inhouseCollaboratorTicketId
        inhouseCustomerTicketId
        externalCollaboratorTicketId
        externalCustomerTicketId
        public
        customersCustomer
        parent
        children
        projectOrigin
        projectTarget
        invitationFlag
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        approveFlag
        bookmark
        ticketType
        deadLine
        personInCharge
        status
        sprint
        priority
        processingOrder
        approvePageLink {
          link
          expireTime
        }
        publicType
        publicRange
        lastMessage
        anonymousUserInfo
        anonymousQaStatus
        updatedForCustomer
        updatedForDesk
        footPrint
        viewPermission
        additionalViewPermission
        editPermission
        additionalEditPermission
        chatPermission
        deletedAt
        timeVectorInfo
        promptFlag
        contentPagePdf
        totalToken
        isSynonym
        metaData
        promptData
        deliverStatus
        deliverInfo {
          status
          deliverAt
          sender
          __typename
        }
      }
      nextToken
    }
  }
`;
export const queryTicketGSI3 = /* GraphQL */ `
  query QueryTicketGSI3(
    $ticketId: ID
    $dataTypeDataLang: ModelTicketTicketGSI3CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTicketGSI3(
      ticketId: $ticketId
      dataTypeDataLang: $dataTypeDataLang
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketId
        dataType
        dataID
        dataLang
        dataValue
        usage
        formId
        affiliationOrganization
        affiliationProject
        customer
        readPermission
        additionalReadPermission
        writePermission
        additionalWritePermission
        createdAt
        updatedAt
        creator
        updator
        proxiedUser
        proxyCreator
        liveOrArchived
        chatThreadWithCustomer
        chatThreadInProject
        inhouseCollaboratorTicketId
        inhouseCustomerTicketId
        externalCollaboratorTicketId
        externalCustomerTicketId
        public
        customersCustomer
        parent
        children
        projectOrigin
        projectTarget
        invitationFlag
        invitationInfo {
          adminEmail
          adminFirstName
          adminLastName
          orgName
          projectName
          projectInvite
          projectType
          customerEmail
          customerLastName
          customerFirstName
          linkInvite
          guestContractRegister {
            id
            mailAddress
            userId
            firstName
            lastName
            corporationPhone
            corporationName
            departmentName
            subDomain
            password
            contractType
            inviter
            userType
            projectType
            projectName
            ticketId
            projectInvite
          }
          expireAt
          maxGeneralId
          numberGeneralIdLeft
          subDomainInvited
        }
        approveFlag
        bookmark
        ticketType
        deadLine
        personInCharge
        status
        sprint
        priority
        processingOrder
        approvePageLink {
          link
          expireTime
        }
        publicType
        publicRange
        lastMessage
        anonymousUserInfo
        anonymousQaStatus
        updatedForCustomer
        updatedForDesk
        footPrint
        viewPermission
        additionalViewPermission
        editPermission
        additionalEditPermission
        chatPermission
        deletedAt
        timeVectorInfo
        promptFlag
        contentPagePdf
        totalToken
        isSynonym
        metaData
        promptData
        deliverStatus
        deliverInfo {
          status
          deliverAt
          sender
          __typename
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      usage
      affiliationOrganization
      image
      active
      firstName
      middleName
      lastName
      languages
      privilege
      type
      mail
      tel
      agent
      projectInuse
      readNotificationAt
      workSpace
      creator
      createdAt
      updator
      updatedAt
      expireAt
      forgotPasswordSessionExpireAt
      settingNotification
      isGuest
      userSetting
      projectRejected
      workLastForm
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usage
        affiliationOrganization
        image
        active
        firstName
        middleName
        lastName
        languages
        privilege
        type
        mail
        tel
        agent
        projectInuse
        readNotificationAt
        workSpace
        creator
        createdAt
        updator
        updatedAt
        expireAt
        forgotPasswordSessionExpireAt
        settingNotification
        isGuest
        userSetting
        projectRejected
        workLastForm
      }
      nextToken
    }
  }
`;
export const listUserByCorporationId = /* GraphQL */ `
  query ListUserByCorporationId(
    $affiliationOrganization: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserByCorporationId(
      affiliationOrganization: $affiliationOrganization
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        affiliationOrganization
        image
        active
        firstName
        middleName
        lastName
        languages
        privilege
        type
        mail
        tel
        agent
        projectInuse
        readNotificationAt
        workSpace
        creator
        createdAt
        updator
        updatedAt
        expireAt
        forgotPasswordSessionExpireAt
        settingNotification
        isGuest
        userSetting
        projectRejected
        workLastForm
      }
      nextToken
    }
  }
`;
export const listUserByEmail = /* GraphQL */ `
  query ListUserByEmail(
    $mail: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserByEmail(
      mail: $mail
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage
        affiliationOrganization
        image
        active
        firstName
        middleName
        lastName
        languages
        privilege
        type
        mail
        tel
        agent
        projectInuse
        readNotificationAt
        workSpace
        creator
        createdAt
        updator
        updatedAt
        expireAt
        forgotPasswordSessionExpireAt
        settingNotification
        isGuest
        userSetting
        projectRejected
        workLastForm
      }
      nextToken
    }
  }
`;
export const getUserBookmark = /* GraphQL */ `
  query GetUserBookmark($id: ID!) {
    getUserBookmark(id: $id) {
      id
      userId
      bookmarkId
      ticketId
      projectSetting
      bookmarkShareRange
      bookmarkAffiliationProject
      ticketAffiliationProject
      ticketAffiliationOrganization
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const listUserBookmarks = /* GraphQL */ `
  query ListUserBookmarks(
    $filter: ModelUserBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        bookmarkId
        ticketId
        projectSetting
        bookmarkShareRange
        bookmarkAffiliationProject
        ticketAffiliationProject
        ticketAffiliationOrganization
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryUserBookmarkGSI1 = /* GraphQL */ `
  query QueryUserBookmarkGSI1(
    $bookmarkId: ID
    $userIdTicketIdCreatedAt: ModelUserBookmarkUserBookmarkGSI1CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryUserBookmarkGSI1(
      bookmarkId: $bookmarkId
      userIdTicketIdCreatedAt: $userIdTicketIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bookmarkId
        ticketId
        projectSetting
        bookmarkShareRange
        bookmarkAffiliationProject
        ticketAffiliationProject
        ticketAffiliationOrganization
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryUserBookmarkGSI2 = /* GraphQL */ `
  query QueryUserBookmarkGSI2(
    $ticketId: ID
    $userIdBookmarkIdCreatedAt: ModelUserBookmarkUserBookmarkGSI2CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryUserBookmarkGSI2(
      ticketId: $ticketId
      userIdBookmarkIdCreatedAt: $userIdBookmarkIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bookmarkId
        ticketId
        projectSetting
        bookmarkShareRange
        bookmarkAffiliationProject
        ticketAffiliationProject
        ticketAffiliationOrganization
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryUserBookmarkGSI3 = /* GraphQL */ `
  query QueryUserBookmarkGSI3(
    $ticketId: ID
    $projectSettingUserIdBookmarkIdCreatedAt: ModelUserBookmarkUserBookmarkGSI3CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryUserBookmarkGSI3(
      ticketId: $ticketId
      projectSettingUserIdBookmarkIdCreatedAt: $projectSettingUserIdBookmarkIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bookmarkId
        ticketId
        projectSetting
        bookmarkShareRange
        bookmarkAffiliationProject
        ticketAffiliationProject
        ticketAffiliationOrganization
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryUserBookmarkGSI4 = /* GraphQL */ `
  query QueryUserBookmarkGSI4(
    $projectSetting: ID
    $userIdBookmarkIdCreatedAt: ModelUserBookmarkUserBookmarkGSI4CompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryUserBookmarkGSI4(
      projectSetting: $projectSetting
      userIdBookmarkIdCreatedAt: $userIdBookmarkIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bookmarkId
        ticketId
        projectSetting
        bookmarkShareRange
        bookmarkAffiliationProject
        ticketAffiliationProject
        ticketAffiliationOrganization
        creator
        createdAt
        updator
        updatedAt
      }
      nextToken
    }
  }
`;
export const getView = /* GraphQL */ `
  query GetView($id: ID!) {
    getView(id: $id) {
      id
      name
      affiliationOrganization
      affiliationProject
      affiliationView
      visibleRange
      visibleType
      structure
      viewType
      definitionView
      status
      order
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
export const listViews = /* GraphQL */ `
  query ListViews(
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        affiliationOrganization
        affiliationProject
        affiliationView
        visibleRange
        visibleType
        structure
        viewType
        definitionView
        status
        order
        creator
        updator
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryViewGSI1 = /* GraphQL */ `
  query QueryViewGSI1(
    $visibleRange: ID
    $visibleType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryViewGSI1(
      visibleRange: $visibleRange
      visibleType: $visibleType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        affiliationOrganization
        affiliationProject
        affiliationView
        visibleRange
        visibleType
        structure
        viewType
        definitionView
        status
        order
        creator
        updator
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
