/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const changePermission = /* GraphQL */ `
  mutation ChangePermission(
    $changePermissionRequestDto: ChangePermissionRequestDto!
  ) {
    changePermission(changePermissionRequestDto: $changePermissionRequestDto) {
      status
    }
  }
`;
export const changePermissionUserOfProject = /* GraphQL */ `
  mutation ChangePermissionUserOfProject(
    $changePermissionUserOfProjectRequestDto: ChangePermissionUserOfProjectRequestDto!
  ) {
    changePermissionUserOfProject(
      changePermissionUserOfProjectRequestDto: $changePermissionUserOfProjectRequestDto
    ) {
      status
    }
  }
`;
export const editUserResource = /* GraphQL */ `
  mutation EditUserResource(
    $editUserResourceRequestDto: EditUserResourceRequestDto!
  ) {
    editUserResource(editUserResourceRequestDto: $editUserResourceRequestDto) {
      status
      error
      userSuccessList
      userFailList
      errorMessage
    }
  }
`;
export const editUserInfo = /* GraphQL */ `
  mutation EditUserInfo($editUserInfoRequestDto: EditUserInfoRequestDto!) {
    editUserInfo(editUserInfoRequestDto: $editUserInfoRequestDto) {
      id
      usage
      affiliationOrganization
      image
      active
      firstName
      lastName
      languages
      privilege
      type
      mail
      tel
      projectInuse
      creator
      createdAt
      updator
      updatedAt
      workSpace
      workLastForm
    }
  }
`;
export const editCorporation = /* GraphQL */ `
  mutation EditCorporation(
    $editCorporationRequestDto: EditCorporationRequestDto!
  ) {
    editCorporation(editCorporationRequestDto: $editCorporationRequestDto) {
      name
      image
      logoFileName
      limitValue
      corporationSetting
    }
  }
`;
export const saveTicketDraft = /* GraphQL */ `
  mutation SaveTicketDraft($createTicketRequestDto: CreateTicketRequestDto!) {
    saveTicketDraft(createTicketRequestDto: $createTicketRequestDto) {
      createTicketStatus
      errorMessage
      id
      affiliationOrganization
      outline
      description
      createdAt
      readPermission
      affiliationProject
      dataType
      dataLang
      liveOrArchived
      creator
      footPrint
    }
  }
`;
export const sendTicketFromDraft = /* GraphQL */ `
  mutation SendTicketFromDraft(
    $sendTicketFromDraftRequestDto: SendTicketFormDraftRequestDto!
  ) {
    sendTicketFromDraft(
      sendTicketFromDraftRequestDto: $sendTicketFromDraftRequestDto
    ) {
      createTicketStatus
      errorMessage
      id
      affiliationOrganization
      outline
      description
      createdAt
      readPermission
      affiliationProject
      dataType
      dataLang
      liveOrArchived
      creator
      footPrint
    }
  }
`;
export const createNewChatPost = /* GraphQL */ `
  mutation CreateNewChatPost(
    $createNewChatPostRequestDto: CreateNewChatPostRequestDto!
  ) {
    createNewChatPost(
      createNewChatPostRequestDto: $createNewChatPostRequestDto
    ) {
      contentId
      corporationId
      postType
      chatThreadId
      content
      creatorId
      delegateUserId
      createdAt
      fileUrl
      fileSize
      status
      footPrint {
        userList
      }
      history {
        content
        updatedAt
      }
    }
  }
`;
export const editForms = /* GraphQL */ `
  mutation EditForms($editFormRequestDto: EditFormsRequestDto!) {
    editForms(editFormRequestDto: $editFormRequestDto) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      sysField
      relatedMasterForm
      settingAutoAdd
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      newShareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      promptKnowledgeFormFlag
      isSynonym
      definitionVariant
    }
  }
`;
export const editCorporationDetail = /* GraphQL */ `
  mutation EditCorporationDetail(
    $editCorporationDetailRequestDto: EditCorporationDetailRequestDto!
  ) {
    editCorporationDetail(
      editCorporationDetailRequestDto: $editCorporationDetailRequestDto
    ) {
      id
      updateStatus
      errorCode
      errorList {
        maxNumOfGeneralUser
        maxNumOfAgent
        maxNumOfGuestUser
        maxNumOfCustomer
        maxNumOfGeneralProject
        maxNumOfGuestProject
        maxNumOfGuestDesk
        maxNumOfTicket
        maxNumOfStorage
      }
    }
  }
`;
export const updateFormFieldResource = /* GraphQL */ `
  mutation UpdateFormFieldResource(
    $updateFormFieldResourceRequestDto: UpdateFormFieldResourceRequestDto!
  ) {
    updateFormFieldResource(
      updateFormFieldResourceRequestDto: $updateFormFieldResourceRequestDto
    ) {
      status
      listFieldAddToFormResponse
      fieldListAddNew
      listFieldUpdateSharePermission
      fieldDataAddNew
      createFormResult
      updateFormResult
      fieldEditData
      fieldEditItemData
    }
  }
`;
export const createNewCorporation = /* GraphQL */ `
  mutation CreateNewCorporation(
    $id: String
    $active: Int
    $mailAddress: String!
    $userId: String!
    $firstName: String
    $lastName: String
    $corporationName: String
    $corporationPhone: String
    $departmentName: String
    $subDomain: String!
    $password: String!
    $contractType: String
    $inviter: String
    $userType: String
    $rootFlag: Int
    $owner: String
    $deskService: Int
    $knowledgeService: Int
    $taskManagementService: Int
    $publicService: Int
    $maxGeneralId: Int
    $maxGeneralUser: Int
    $productId: String
    $editionId: String
    $jobTitle: String
    $industry: String
    $companyName: String
  ) {
    createNewCorporation(
      id: $id
      active: $active
      mailAddress: $mailAddress
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      corporationName: $corporationName
      corporationPhone: $corporationPhone
      departmentName: $departmentName
      subDomain: $subDomain
      password: $password
      contractType: $contractType
      inviter: $inviter
      userType: $userType
      rootFlag: $rootFlag
      owner: $owner
      deskService: $deskService
      knowledgeService: $knowledgeService
      taskManagementService: $taskManagementService
      publicService: $publicService
      maxGeneralId: $maxGeneralId
      maxGeneralUser: $maxGeneralUser
      productId: $productId
      editionId: $editionId
      jobTitle: $jobTitle
      industry: $industry
      companyName: $companyName
    ) {
      corporationInfo {
        id
        usage
        affiliationOrganization
        image
        logoFileName
        active
        name
        languages
        autoTranslation
        organizationDomain
        contractType
        contractDate
        lastDayOfContract
        creditCard
        ZIPcode
        address
        mail
        tel
        departmentName
        activatedFunctions
        limitValue {
          maxNumOfGeneralUser
          numOfGeneralUser
          maxNumOfAgent
          numOfAgent
          maxNumOfGuestUser
          numOfGuestUser
          maxNumOfCustomer
          numOfCustomer
          maxNumOfGeneralProject
          numOfGeneralProject
          maxNumOfGuestProject
          numOfGuestProject
          maxNumOfGuestDesk
          numOfGuestDesk
          numOfTicket
          maxNumOfTicket
          numOfStorage
          maxNumOfStorage
          numOfInternalUser
          numOfB2CCustomer
          numOfProjectDeskOn
          numOfProjectPublicOn
          numOfTicketKnowledge
          internalQACounterSettings
          publicWebForm
          emailResponseFunction
          knowledgeBot
          synonymSearch
          batchCommandService
          convertPDF
          chatAI
          usingChatAI
          lincenseKeyPermission
          lincenseKeySetting
          lincenseKeyValue
          chatGPTModel
          knnValue
          minScore
          aiAuto
          systemPrompt
          interatorSystemPrompt
          usingAIAuto
          advancedOptionSettings
          logAnalysis
          ipRestriction
          maxNumOfTicketKnowledge
          maxNumberOfFormat
          maxNumberOfQACounters
          maxNumberOfSupportedCounters
          maxNumOfInternalUser
          maxNumOfB2CCustomer
          numberOfFormat
          numberOfQACounters
          numberOfSupportedCounters
          storageVolume
          generalUserFee
          basicCharge
          taskManagement
          vectorTicketDataStatus
          deskService
          knowledgeService
          publicService
          publicServiceDeskSettings
          solutionsDeskOfKnowledge
          publicKnowledgeSite
          numOfUserInOrg
          numOfPartnerId
        }
        inviter
        inviterOrganizationDomain
        corporationInvited
        creator
        createdAt
        updator
        updatedAt
        rootFlag
        owner
        deskService
        knowledgeService
        taskManagementService
        publicService
        metaData
        versionId
        contractId
        contractStatus
      }
      userInfo {
        id
        usage
        affiliationOrganization
        image
        active
        firstName
        middleName
        lastName
        languages
        privilege
        type
        mail
        tel
        agent
        projectInuse
        readNotificationAt
        workSpace
        creator
        createdAt
        updator
        updatedAt
        expireAt
        forgotPasswordSessionExpireAt
        settingNotification
        isGuest
        userSetting
        projectRejected
        workLastForm
      }
      projectBaseInfo {
        id
        projectId
        usage
        affiliationOrganization
        image
        active
        name
        languages
        autoTranslation
        id2
        privilege
        type
        mail
        tel
        projectInvite
        projectInvited
        iframeSetting
        chatbotSetting
        isDesk
        isPublic
        isGuest
        publicKnowledgeSiteSetting
        projectAlias
        projectSetting
        chatBotCategorySetting
        mailSetting
        userLogCache
        creator
        createdAt
        updator
        updatedAt
      }
      initData {
        userId
        corporationId
        projectListId
      }
    }
  }
`;
export const createNewTicket = /* GraphQL */ `
  mutation CreateNewTicket($createTicketRequestDto: CreateTicketRequestDto!) {
    createNewTicket(createTicketRequestDto: $createTicketRequestDto) {
      createTicketStatus
      errorMessage
      id
      affiliationOrganization
      outline
      description
      createdAt
      readPermission
      affiliationProject
      dataType
      dataLang
      liveOrArchived
      creator
      footPrint
    }
  }
`;
export const createGuestContract = /* GraphQL */ `
  mutation CreateGuestContract(
    $guestContractRequestDto: CreateGuestContractRequestDto!
  ) {
    createGuestContract(guestContractRequestDto: $guestContractRequestDto) {
      corporationId
      createdAt
      status
      isCreateApprovePage
    }
  }
`;
export const editTicket = /* GraphQL */ `
  mutation EditTicket($updateTicketRequestDto: UpdateTicketRequestDto!) {
    editTicket(updateTicketRequestDto: $updateTicketRequestDto) {
      id
      corporationId
      usage
      updateStatus
      createdAt
      updatedAt
      anonymousQaStatus
      wasUpdatedBefore
      errorCode
      isNotiToCustomerDone
      isNotiToHelpDeskDone
      updatedChildrenTicketIdList
    }
  }
`;
export const createNewField = /* GraphQL */ `
  mutation CreateNewField(
    $fieldList: [CreateNewFieldRequestDto]!
    $type: String
  ) {
    createNewField(fieldList: $fieldList, type: $type) {
      field {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        relatedMasterForm
        settingAutoAdd
        shareOption
        shareRange
        viewPermission
        editPermission
        chatPermission
        reviewFlag
        promptKnowledgeFormFlag
        isSynonym
        definitionVariant
      }
      itemList {
        id
        usage
        name
        affiliationOrganization
        affiliationProject
        affiliationField
        createdAt
        updatedAt
        creator
        updator
        type
        structure
        viewForCustomer
        definition
        explanation
        usingProjects
        usingTickets
        order
        relatedMasterForm
        settingAutoAdd
        shareOption
        shareRange
        viewPermission
        editPermission
        chatPermission
        reviewFlag
        promptKnowledgeFormFlag
        isSynonym
        definitionVariant
      }
    }
  }
`;
export const createAnonymousQa = /* GraphQL */ `
  mutation CreateAnonymousQa(
    $createAnonymousQaRequestDto: CreateAnonymousQaRequestDto!
  ) {
    createAnonymousQa(
      createAnonymousQaRequestDto: $createAnonymousQaRequestDto
    ) {
      id
      affiliationOrganization
      outline
      description
      createdAt
      readPermission
      affiliationProject
      dataType
      dataLang
      liveOrArchived
      creator
      wasUpdatedBefore
      errorMessage
    }
  }
`;
export const addCustomerUserToProject = /* GraphQL */ `
  mutation AddCustomerUserToProject(
    $addCustomerUserToProjectRequestDto: AddCustomerUserToProjectRequestDto!
  ) {
    addCustomerUserToProject(
      addCustomerUserToProjectRequestDto: $addCustomerUserToProjectRequestDto
    ) {
      status
      successList {
        clientId
        id
        usage
        affiliationOrganization
        image
        active
        firstName
        middleName
        lastName
        languages
        privilege
        type
        mail
        tel
        projectInuse
        readNotificationAt
        creator
        createdAt
        updator
        updatedAt
      }
      errorList {
        clientId
        firstName
        lastName
        mail
        error
      }
    }
  }
`;
export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword(
    $forgotPasswordRequestDto: ForgotPasswordRequestDto!
  ) {
    forgotPassword(forgotPasswordRequestDto: $forgotPasswordRequestDto) {
      isSuccess
      messageCode
    }
  }
`;
export const restoreUser = /* GraphQL */ `
  mutation RestoreUser($restoreUserRequestDto: RestoreUserRequestDto!) {
    restoreUser(restoreUserRequestDto: $restoreUserRequestDto) {
      status
      errorMessage
    }
  }
`;
export const removeTicket = /* GraphQL */ `
  mutation RemoveTicket($removeTicketRequestDto: RemoveTicketRequestDto!) {
    removeTicket(removeTicketRequestDto: $removeTicketRequestDto) {
      status
      errorMessage
      removedChildrenTicketIdList
    }
  }
`;
export const createNewNotification = /* GraphQL */ `
  mutation CreateNewNotification(
    $createNotificationRequestDto: CreateNotificationRequestDto!
  ) {
    createNewNotification(
      createNotificationRequestDto: $createNotificationRequestDto
    ) {
      isSuccess
    }
  }
`;
export const blockAndDeleteOrganization = /* GraphQL */ `
  mutation BlockAndDeleteOrganization(
    $blockAndDeleteOrganizationRequestDto: BlockAndDeleteOrganizationRequestDto!
  ) {
    blockAndDeleteOrganization(
      blockAndDeleteOrganizationRequestDto: $blockAndDeleteOrganizationRequestDto
    ) {
      success
      data {
        id
        usage
        affiliationOrganization
        image
        logoFileName
        active
        name
        languages
        autoTranslation
        organizationDomain
        contractType
        contractDate
        lastDayOfContract
        creditCard
        ZIPcode
        address
        mail
        tel
        departmentName
        activatedFunctions
        limitValue {
          maxNumOfGeneralUser
          numOfGeneralUser
          maxNumOfAgent
          numOfAgent
          maxNumOfGuestUser
          numOfGuestUser
          maxNumOfCustomer
          numOfCustomer
          maxNumOfGeneralProject
          numOfGeneralProject
          maxNumOfGuestProject
          numOfGuestProject
          maxNumOfGuestDesk
          numOfGuestDesk
          numOfTicket
          maxNumOfTicket
          numOfStorage
          maxNumOfStorage
          numOfInternalUser
          numOfB2CCustomer
          numOfProjectDeskOn
          numOfProjectPublicOn
          numOfTicketKnowledge
          internalQACounterSettings
          publicWebForm
          emailResponseFunction
          knowledgeBot
          synonymSearch
          batchCommandService
          convertPDF
          chatAI
          usingChatAI
          lincenseKeyPermission
          lincenseKeySetting
          lincenseKeyValue
          chatGPTModel
          knnValue
          minScore
          aiAuto
          systemPrompt
          interatorSystemPrompt
          usingAIAuto
          advancedOptionSettings
          logAnalysis
          ipRestriction
          maxNumOfTicketKnowledge
          maxNumberOfFormat
          maxNumberOfQACounters
          maxNumberOfSupportedCounters
          maxNumOfInternalUser
          maxNumOfB2CCustomer
          numberOfFormat
          numberOfQACounters
          numberOfSupportedCounters
          storageVolume
          generalUserFee
          basicCharge
          taskManagement
          vectorTicketDataStatus
          deskService
          knowledgeService
          publicService
          publicServiceDeskSettings
          solutionsDeskOfKnowledge
          publicKnowledgeSite
          numOfUserInOrg
          numOfPartnerId
        }
        inviter
        inviterOrganizationDomain
        corporationInvited
        creator
        createdAt
        updator
        updatedAt
        rootFlag
        owner
        deskService
        knowledgeService
        taskManagementService
        publicService
        metaData
        versionId
        contractId
        contractStatus
        corporationSetting
      }
      errors
    }
  }
`;
export const updateNewAttachFile = /* GraphQL */ `
  mutation UpdateNewAttachFile(
    $updateAttachFileRequestDto: UpdateAttachFileRequestDto!
  ) {
    updateNewAttachFile(
      updateAttachFileRequestDto: $updateAttachFileRequestDto
    ) {
      isSuccess
    }
  }
`;
export const updateProjectResource = /* GraphQL */ `
  mutation UpdateProjectResource(
    $updateProjectResourceRequestDto: UpdateProjectResourceRequestDto!
  ) {
    updateProjectResource(
      updateProjectResourceRequestDto: $updateProjectResourceRequestDto
    ) {
      status
      statusAddProject
      statusEditProject
      errorMessage
      data
    }
  }
`;
export const updateApiPublic = /* GraphQL */ `
  mutation UpdateApiPublic($updateApiPublicRequestDto: String!) {
    updateApiPublic(updateApiPublicRequestDto: $updateApiPublicRequestDto) {
      success
      payload
      errors
    }
  }
`;
export const updateRootOrganizationPermission = /* GraphQL */ `
  mutation UpdateRootOrganizationPermission(
    $updateRootOrganizationPermissionRequestDto: UpdateRootOrganizationPermissionRequestDto!
  ) {
    updateRootOrganizationPermission(
      updateRootOrganizationPermissionRequestDto: $updateRootOrganizationPermissionRequestDto
    ) {
      id
      rootFlag
    }
  }
`;
export const createSupportChatPost = /* GraphQL */ `
  mutation CreateSupportChatPost($params: String!) {
    createSupportChatPost(params: $params) {
      id
      createdAt
      currentTime
    }
  }
`;
export const createTicketByUploadCsv = /* GraphQL */ `
  mutation CreateTicketByUploadCsv(
    $createTicketByUploadCsvRequestDto: CreateTicketByUploadCsvRequestDto!
  ) {
    createTicketByUploadCsv(
      createTicketByUploadCsvRequestDto: $createTicketByUploadCsvRequestDto
    ) {
      status
      id
      isSkipUpload
      errorMessage
      listTicket
    }
  }
`;
export const deleteUserLog = /* GraphQL */ `
  mutation DeleteUserLog($deleteUserLogRequestDto: DeleteUserLogRequestDto!) {
    deleteUserLog(deleteUserLogRequestDto: $deleteUserLogRequestDto) {
      status
    }
  }
`;
export const editProductManagement = /* GraphQL */ `
  mutation EditProductManagement(
    $editProductManagementRequestDto: EditProductManagementRequestDto!
  ) {
    editProductManagement(
      editProductManagementRequestDto: $editProductManagementRequestDto
    ) {
      status
      listProductErrors
      errorMessage
      listEditionErrors
    }
  }
`;
export const triggerElsIndexDataByListTicketId = /* GraphQL */ `
  mutation TriggerElsIndexDataByListTicketId($ticketIdList: [String]) {
    triggerElsIndexDataByListTicketId(ticketIdList: $ticketIdList) {
      status
    }
  }
`;
export const editCorporationContractDetail = /* GraphQL */ `
  mutation EditCorporationContractDetail(
    $editCorporationContractDetailRequestDto: EditCorporationContractDetailRequestDto!
  ) {
    editCorporationContractDetail(
      editCorporationContractDetailRequestDto: $editCorporationContractDetailRequestDto
    ) {
      status
      data
      errors
    }
  }
`;
export const createKibanaLog = /* GraphQL */ `
  mutation CreateKibanaLog($params: String!) {
    createKibanaLog(params: $params) {
      success
      payload
      errors
    }
  }
`;
export const sendAIChat = /* GraphQL */ `
  mutation SendAIChat($sendAIChatRequestDto: SendAIChatRequestDto!) {
    sendAIChat(sendAIChatRequestDto: $sendAIChatRequestDto) {
      responseMessage
      gptParams
    }
  }
`;
export const sendAIChatBot = /* GraphQL */ `
  mutation SendAIChatBot($sendAIChatBotRequestDto: SendAIChatBotRequestDto!) {
    sendAIChatBot(sendAIChatBotRequestDto: $sendAIChatBotRequestDto) {
      responseMessage
      gptParams
    }
  }
`;
export const updateAISettingsForKnowledgePrompt = /* GraphQL */ `
  mutation UpdateAISettingsForKnowledgePrompt(
    $updateAISettingsForKnowledgePromptRequestDto: UpdateAISettingsForKnowledgePromptRequestDto!
  ) {
    updateAISettingsForKnowledgePrompt(
      updateAISettingsForKnowledgePromptRequestDto: $updateAISettingsForKnowledgePromptRequestDto
    ) {
      status
      result
    }
  }
`;
export const publishOpenAIMessageStream = /* GraphQL */ `
  mutation PublishOpenAIMessageStream($params: SendMessageAIStreamRequestDto) {
    publishOpenAIMessageStream(params: $params) {
      message
      aiChatPostId
      seq
      isEnd
    }
  }
`;
export const uploadCSVStep2Handle = /* GraphQL */ `
  mutation UploadCSVStep2Handle(
    $uploadCSVStep2HandleRequestDto: UploadCSVStep2HandleRequestDto!
  ) {
    uploadCSVStep2Handle(
      uploadCSVStep2HandleRequestDto: $uploadCSVStep2HandleRequestDto
    ) {
      status
    }
  }
`;
export const bulkUpdateTicket = /* GraphQL */ `
  mutation BulkUpdateTicket(
    $bulkUpdateTicketRequestDto: BulkUpdateTicketRequestDto!
  ) {
    bulkUpdateTicket(bulkUpdateTicketRequestDto: $bulkUpdateTicketRequestDto) {
      status
      taskId
      message
    }
  }
`;
export const testSifApi = /* GraphQL */ `
  mutation TestSifApi($testSifRequestDto: TestSifRequestDto!) {
    testSifApi(testSifRequestDto: $testSifRequestDto) {
      status
      body
    }
  }
`;
export const bookmarkUpdateAPI = /* GraphQL */ `
  mutation BookmarkUpdateAPI(
    $bookmarkUpdateAPIRequestDto: BookmarkUpdateAPIRequestDto!
  ) {
    bookmarkUpdateAPI(
      bookmarkUpdateAPIRequestDto: $bookmarkUpdateAPIRequestDto
    ) {
      status
      data
      errorMsg
      errorCode
    }
  }
`;
export const AiAutoLogUpdate = /* GraphQL */ `
  mutation AiAutoLogUpdate(
    $AiAutoLogUpdateRequestDto: AiAutoLogUpdateRequestDto!
  ) {
    AiAutoLogUpdate(AiAutoLogUpdateRequestDto: $AiAutoLogUpdateRequestDto) {
      status
      data
      errorMsg
      errorCode
    }
  }
`;
export const createAIChatPost = /* GraphQL */ `
  mutation CreateAIChatPost(
    $input: CreateAIChatPostInput!
    $condition: ModelAIChatPostConditionInput
  ) {
    createAIChatPost(input: $input, condition: $condition) {
      id
      corporationId
      affiliationProject
      postType
      chatThreadId
      role
      content
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
    }
  }
`;
export const updateAIChatPost = /* GraphQL */ `
  mutation UpdateAIChatPost(
    $input: UpdateAIChatPostInput!
    $condition: ModelAIChatPostConditionInput
  ) {
    updateAIChatPost(input: $input, condition: $condition) {
      id
      corporationId
      affiliationProject
      postType
      chatThreadId
      role
      content
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
    }
  }
`;
export const deleteAIChatPost = /* GraphQL */ `
  mutation DeleteAIChatPost(
    $input: DeleteAIChatPostInput!
    $condition: ModelAIChatPostConditionInput
  ) {
    deleteAIChatPost(input: $input, condition: $condition) {
      id
      corporationId
      affiliationProject
      postType
      chatThreadId
      role
      content
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
    }
  }
`;
export const createAIChatThread = /* GraphQL */ `
  mutation CreateAIChatThread(
    $input: CreateAIChatThreadInput!
    $condition: ModelAIChatThreadConditionInput
  ) {
    createAIChatThread(input: $input, condition: $condition) {
      id
      corporationId
      affiliationProject
      chatThreadId
      optionSearch {
        methodSearch
        attributeSearch
        keywordList
        fromSearchDate
        toSearchDate
        formatSearch
        sortType
      }
      drillDownSearch {
        ticketType
        creator
        updator
        proxiedUser
        suffixOfAttachments
        form
        tagFields {
          fieldId
          fieldItemId
        }
        statusFields {
          fieldId
          fieldItemId
        }
        publicRange
        sharedBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
        privateBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
      }
      clientUUId
      ticketId
      chatThreadType
      status
      metaData {
        paramsReplayS3Path
      }
      aiLogMetaData
      createdAt
      updatedAt
      startTime
      endTime
      creatorId
      updatorId
    }
  }
`;
export const updateAIChatThread = /* GraphQL */ `
  mutation UpdateAIChatThread(
    $input: UpdateAIChatThreadInput!
    $condition: ModelAIChatThreadConditionInput
  ) {
    updateAIChatThread(input: $input, condition: $condition) {
      id
      corporationId
      affiliationProject
      chatThreadId
      optionSearch {
        methodSearch
        attributeSearch
        keywordList
        fromSearchDate
        toSearchDate
        formatSearch
        sortType
      }
      drillDownSearch {
        ticketType
        creator
        updator
        proxiedUser
        suffixOfAttachments
        form
        tagFields {
          fieldId
          fieldItemId
        }
        statusFields {
          fieldId
          fieldItemId
        }
        publicRange
        sharedBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
        privateBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
      }
      clientUUId
      ticketId
      chatThreadType
      status
      metaData {
        paramsReplayS3Path
      }
      aiLogMetaData
      createdAt
      updatedAt
      startTime
      endTime
      creatorId
      updatorId
    }
  }
`;
export const deleteAIChatThread = /* GraphQL */ `
  mutation DeleteAIChatThread(
    $input: DeleteAIChatThreadInput!
    $condition: ModelAIChatThreadConditionInput
  ) {
    deleteAIChatThread(input: $input, condition: $condition) {
      id
      corporationId
      affiliationProject
      chatThreadId
      optionSearch {
        methodSearch
        attributeSearch
        keywordList
        fromSearchDate
        toSearchDate
        formatSearch
        sortType
      }
      drillDownSearch {
        ticketType
        creator
        updator
        proxiedUser
        suffixOfAttachments
        form
        tagFields {
          fieldId
          fieldItemId
        }
        statusFields {
          fieldId
          fieldItemId
        }
        publicRange
        sharedBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
        privateBookmark {
          bookmarkId
          userSelectId
          projectSelectId
        }
      }
      clientUUId
      ticketId
      chatThreadType
      status
      metaData {
        paramsReplayS3Path
      }
      aiLogMetaData
      createdAt
      updatedAt
      startTime
      endTime
      creatorId
      updatorId
    }
  }
`;
export const createAttachFile = /* GraphQL */ `
  mutation CreateAttachFile(
    $input: CreateAttachFileInput!
    $condition: ModelAttachFileConditionInput
  ) {
    createAttachFile(input: $input, condition: $condition) {
      id
      relatedId
      affiliationOrganization
      affiliationProject
      originId
      s3Path
      fileInfo
      thumbnailInfo
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
export const updateAttachFile = /* GraphQL */ `
  mutation UpdateAttachFile(
    $input: UpdateAttachFileInput!
    $condition: ModelAttachFileConditionInput
  ) {
    updateAttachFile(input: $input, condition: $condition) {
      id
      relatedId
      affiliationOrganization
      affiliationProject
      originId
      s3Path
      fileInfo
      thumbnailInfo
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttachFile = /* GraphQL */ `
  mutation DeleteAttachFile(
    $input: DeleteAttachFileInput!
    $condition: ModelAttachFileConditionInput
  ) {
    deleteAttachFile(input: $input, condition: $condition) {
      id
      relatedId
      affiliationOrganization
      affiliationProject
      originId
      s3Path
      fileInfo
      thumbnailInfo
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
export const createBookmark = /* GraphQL */ `
  mutation CreateBookmark(
    $input: CreateBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    createBookmark(input: $input, condition: $condition) {
      id
      affiliationOrganization
      affiliationProject
      name
      definition
      shareRange
      defaultBookmark
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const updateBookmark = /* GraphQL */ `
  mutation UpdateBookmark(
    $input: UpdateBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    updateBookmark(input: $input, condition: $condition) {
      id
      affiliationOrganization
      affiliationProject
      name
      definition
      shareRange
      defaultBookmark
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const deleteBookmark = /* GraphQL */ `
  mutation DeleteBookmark(
    $input: DeleteBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    deleteBookmark(input: $input, condition: $condition) {
      id
      affiliationOrganization
      affiliationProject
      name
      definition
      shareRange
      defaultBookmark
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const createChatPost = /* GraphQL */ `
  mutation CreateChatPost(
    $input: CreateChatPostInput!
    $condition: ModelChatPostConditionInput
  ) {
    createChatPost(input: $input, condition: $condition) {
      id
      ticketAffiliationOrganization
      ticketAffiliationProject
      corporationId
      affiliationProject
      postType
      chatThreadId
      content
      delegateUserId
      fileUrl
      fileSize
      thumbnailInfo
      status
      footPrint {
        userList
      }
      history {
        content
        updatedAt
      }
      mention
      reaction
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
      ticketId
      role
      emailId
      deliverStatus
      deliverAt
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const updateChatPost = /* GraphQL */ `
  mutation UpdateChatPost(
    $input: UpdateChatPostInput!
    $condition: ModelChatPostConditionInput
  ) {
    updateChatPost(input: $input, condition: $condition) {
      id
      ticketAffiliationOrganization
      ticketAffiliationProject
      corporationId
      affiliationProject
      postType
      chatThreadId
      content
      delegateUserId
      fileUrl
      fileSize
      thumbnailInfo
      status
      footPrint {
        userList
      }
      history {
        content
        updatedAt
      }
      mention
      reaction
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
      ticketId
      role
      emailId
      deliverStatus
      deliverAt
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const deleteChatPost = /* GraphQL */ `
  mutation DeleteChatPost(
    $input: DeleteChatPostInput!
    $condition: ModelChatPostConditionInput
  ) {
    deleteChatPost(input: $input, condition: $condition) {
      id
      ticketAffiliationOrganization
      ticketAffiliationProject
      corporationId
      affiliationProject
      postType
      chatThreadId
      content
      delegateUserId
      fileUrl
      fileSize
      thumbnailInfo
      status
      footPrint {
        userList
      }
      history {
        content
        updatedAt
      }
      mention
      reaction
      clientUUId
      seq
      createdAt
      updatedAt
      creatorId
      ticketId
      role
      emailId
      deliverStatus
      deliverAt
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const createChatThread = /* GraphQL */ `
  mutation CreateChatThread(
    $input: CreateChatThreadInput!
    $condition: ModelChatThreadConditionInput
  ) {
    createChatThread(input: $input, condition: $condition) {
      id
      corporationId
      sharedRange
      editableRange
      ticketId
      threadType
      createdAt
      updatedAt
      creatorId
      affiliationProject
      chatThreadId
      clientUUId
    }
  }
`;
export const updateChatThread = /* GraphQL */ `
  mutation UpdateChatThread(
    $input: UpdateChatThreadInput!
    $condition: ModelChatThreadConditionInput
  ) {
    updateChatThread(input: $input, condition: $condition) {
      id
      corporationId
      sharedRange
      editableRange
      ticketId
      threadType
      createdAt
      updatedAt
      creatorId
      affiliationProject
      chatThreadId
      clientUUId
    }
  }
`;
export const deleteChatThread = /* GraphQL */ `
  mutation DeleteChatThread(
    $input: DeleteChatThreadInput!
    $condition: ModelChatThreadConditionInput
  ) {
    deleteChatThread(input: $input, condition: $condition) {
      id
      corporationId
      sharedRange
      editableRange
      ticketId
      threadType
      createdAt
      updatedAt
      creatorId
      affiliationProject
      chatThreadId
      clientUUId
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      affiliationOrganization
      productId
      editionId
      versionId
      kind
      paymentStatus
      paymentMethod
      paymentCycle
      currentPaymentCycle
      paymentDate
      price
      contractStatus
      additionalUpgradeSetting
      additionalPrivateSetting
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      metaData
      trialDays
      startDate
      billStartDate
      billEndDate
      endTrialDate
      createdAt
      updatedAt
      creator
      updator
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      affiliationOrganization
      productId
      editionId
      versionId
      kind
      paymentStatus
      paymentMethod
      paymentCycle
      currentPaymentCycle
      paymentDate
      price
      contractStatus
      additionalUpgradeSetting
      additionalPrivateSetting
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      metaData
      trialDays
      startDate
      billStartDate
      billEndDate
      endTrialDate
      createdAt
      updatedAt
      creator
      updator
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      affiliationOrganization
      productId
      editionId
      versionId
      kind
      paymentStatus
      paymentMethod
      paymentCycle
      currentPaymentCycle
      paymentDate
      price
      contractStatus
      additionalUpgradeSetting
      additionalPrivateSetting
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      metaData
      trialDays
      startDate
      billStartDate
      billEndDate
      endTrialDate
      createdAt
      updatedAt
      creator
      updator
    }
  }
`;
export const createCorporation = /* GraphQL */ `
  mutation CreateCorporation(
    $input: CreateCorporationInput!
    $condition: ModelCorporationConditionInput
  ) {
    createCorporation(input: $input, condition: $condition) {
      id
      usage
      affiliationOrganization
      image
      logoFileName
      active
      name
      languages
      autoTranslation
      organizationDomain
      contractType
      contractDate
      lastDayOfContract
      creditCard
      ZIPcode
      address
      mail
      tel
      departmentName
      activatedFunctions
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      inviter
      inviterOrganizationDomain
      corporationInvited
      creator
      createdAt
      updator
      updatedAt
      rootFlag
      owner
      deskService
      knowledgeService
      taskManagementService
      publicService
      metaData
      versionId
      contractId
      contractStatus
      corporationSetting
    }
  }
`;
export const updateCorporation = /* GraphQL */ `
  mutation UpdateCorporation(
    $input: UpdateCorporationInput!
    $condition: ModelCorporationConditionInput
  ) {
    updateCorporation(input: $input, condition: $condition) {
      id
      usage
      affiliationOrganization
      image
      logoFileName
      active
      name
      languages
      autoTranslation
      organizationDomain
      contractType
      contractDate
      lastDayOfContract
      creditCard
      ZIPcode
      address
      mail
      tel
      departmentName
      activatedFunctions
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      inviter
      inviterOrganizationDomain
      corporationInvited
      creator
      createdAt
      updator
      updatedAt
      rootFlag
      owner
      deskService
      knowledgeService
      taskManagementService
      publicService
      metaData
      versionId
      contractId
      contractStatus
      corporationSetting
    }
  }
`;
export const deleteCorporation = /* GraphQL */ `
  mutation DeleteCorporation(
    $input: DeleteCorporationInput!
    $condition: ModelCorporationConditionInput
  ) {
    deleteCorporation(input: $input, condition: $condition) {
      id
      usage
      affiliationOrganization
      image
      logoFileName
      active
      name
      languages
      autoTranslation
      organizationDomain
      contractType
      contractDate
      lastDayOfContract
      creditCard
      ZIPcode
      address
      mail
      tel
      departmentName
      activatedFunctions
      limitValue {
        maxNumOfGeneralUser
        numOfGeneralUser
        maxNumOfAgent
        numOfAgent
        maxNumOfGuestUser
        numOfGuestUser
        maxNumOfCustomer
        numOfCustomer
        maxNumOfGeneralProject
        numOfGeneralProject
        maxNumOfGuestProject
        numOfGuestProject
        maxNumOfGuestDesk
        numOfGuestDesk
        numOfTicket
        maxNumOfTicket
        numOfStorage
        maxNumOfStorage
        numOfInternalUser
        numOfB2CCustomer
        numOfProjectDeskOn
        numOfProjectPublicOn
        numOfTicketKnowledge
        internalQACounterSettings
        publicWebForm
        emailResponseFunction
        knowledgeBot
        synonymSearch
        batchCommandService
        convertPDF
        chatAI
        usingChatAI
        lincenseKeyPermission
        lincenseKeySetting
        lincenseKeyValue
        chatGPTModel
        knnValue
        minScore
        aiAuto
        systemPrompt
        interatorSystemPrompt
        usingAIAuto
        advancedOptionSettings
        logAnalysis
        ipRestriction
        maxNumOfTicketKnowledge
        maxNumberOfFormat
        maxNumberOfQACounters
        maxNumberOfSupportedCounters
        maxNumOfInternalUser
        maxNumOfB2CCustomer
        numberOfFormat
        numberOfQACounters
        numberOfSupportedCounters
        storageVolume
        generalUserFee
        basicCharge
        taskManagement
        vectorTicketDataStatus
        deskService
        knowledgeService
        publicService
        publicServiceDeskSettings
        solutionsDeskOfKnowledge
        publicKnowledgeSite
        numOfUserInOrg
        numOfPartnerId
      }
      inviter
      inviterOrganizationDomain
      corporationInvited
      creator
      createdAt
      updator
      updatedAt
      rootFlag
      owner
      deskService
      knowledgeService
      taskManagementService
      publicService
      metaData
      versionId
      contractId
      contractStatus
      corporationSetting
    }
  }
`;
export const createForms = /* GraphQL */ `
  mutation CreateForms(
    $input: CreateFormsInput!
    $condition: ModelFormsConditionInput
  ) {
    createForms(input: $input, condition: $condition) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      sysField
      relatedMasterForm
      settingAutoAdd
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      usingTicket
      usedProject
      promptKnowledgeFormFlag
      isSynonym
      definitionVariant
    }
  }
`;
export const updateForms = /* GraphQL */ `
  mutation UpdateForms(
    $input: UpdateFormsInput!
    $condition: ModelFormsConditionInput
  ) {
    updateForms(input: $input, condition: $condition) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      sysField
      relatedMasterForm
      settingAutoAdd
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      usingTicket
      usedProject
      promptKnowledgeFormFlag
      isSynonym
      definitionVariant
    }
  }
`;
export const deleteForms = /* GraphQL */ `
  mutation DeleteForms(
    $input: DeleteFormsInput!
    $condition: ModelFormsConditionInput
  ) {
    deleteForms(input: $input, condition: $condition) {
      id
      usage
      name
      affiliationOrganization
      affiliationProject
      affiliationField
      createdAt
      updatedAt
      creator
      updator
      type
      structure
      viewForCustomer
      definition
      explanation
      usingProjects
      usingTickets
      order
      hasValue
      usingForms
      hitNumber
      sysField
      relatedMasterForm
      settingAutoAdd
      sharedFormList
      sharedFieldList
      shareRange
      shareOption
      viewPermission
      editPermission
      chatPermission
      createTicketForOwnerPermission
      additionalCreateTicketForOwnerPermission
      reviewFlag
      usingTicket
      usedProject
      promptKnowledgeFormFlag
      isSynonym
      definitionVariant
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      systemType
      affiliationOrganization
      affiliationProject
      notificationRange
      sourceObject
      objectType
      eventType
      sourceInfo
      footPrint
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      systemType
      affiliationOrganization
      affiliationProject
      notificationRange
      sourceObject
      objectType
      eventType
      sourceInfo
      footPrint
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      systemType
      affiliationOrganization
      affiliationProject
      notificationRange
      sourceObject
      objectType
      eventType
      sourceInfo
      footPrint
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      productId
      editionId
      name
      order
      metaData
      increaseRate
      usage
      status
      isTrial
      moduleSetting
      creator
      createdAt
      updator
      aiBasicPrompt
      updatedAt
      deletedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      productId
      editionId
      name
      order
      metaData
      increaseRate
      usage
      status
      isTrial
      moduleSetting
      creator
      createdAt
      updator
      aiBasicPrompt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      productId
      editionId
      name
      order
      metaData
      increaseRate
      usage
      status
      isTrial
      moduleSetting
      creator
      createdAt
      updator
      aiBasicPrompt
      updatedAt
      deletedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      projectId
      usage
      affiliationOrganization
      image
      active
      name
      languages
      autoTranslation
      id2
      privilege
      type
      mail
      tel
      projectInvite
      projectInvited
      iframeSetting
      chatbotSetting
      isDesk
      isPublic
      isGuest
      publicKnowledgeSiteSetting
      projectAlias
      projectSetting
      chatBotCategorySetting
      mailSetting
      userLogCache
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      projectId
      usage
      affiliationOrganization
      image
      active
      name
      languages
      autoTranslation
      id2
      privilege
      type
      mail
      tel
      projectInvite
      projectInvited
      iframeSetting
      chatbotSetting
      isDesk
      isPublic
      isGuest
      publicKnowledgeSiteSetting
      projectAlias
      projectSetting
      chatBotCategorySetting
      mailSetting
      userLogCache
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      projectId
      usage
      affiliationOrganization
      image
      active
      name
      languages
      autoTranslation
      id2
      privilege
      type
      mail
      tel
      projectInvite
      projectInvited
      iframeSetting
      chatbotSetting
      isDesk
      isPublic
      isGuest
      publicKnowledgeSiteSetting
      projectAlias
      projectSetting
      chatBotCategorySetting
      mailSetting
      userLogCache
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const createQueue = /* GraphQL */ `
  mutation CreateQueue(
    $input: CreateQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    createQueue(input: $input, condition: $condition) {
      id
      usage
      type
      status
      retry
      info
      planTime
      executeTime
      createdAt
      updatedAt
    }
  }
`;
export const updateQueue = /* GraphQL */ `
  mutation UpdateQueue(
    $input: UpdateQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    updateQueue(input: $input, condition: $condition) {
      id
      usage
      type
      status
      retry
      info
      planTime
      executeTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteQueue = /* GraphQL */ `
  mutation DeleteQueue(
    $input: DeleteQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    deleteQueue(input: $input, condition: $condition) {
      id
      usage
      type
      status
      retry
      info
      planTime
      executeTime
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      usage
      type
      status
      retry
      info
      taskId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      usage
      type
      status
      retry
      info
      taskId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      usage
      type
      status
      retry
      info
      taskId
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      ticketId
      dataType
      dataID
      dataLang
      dataValue
      usage
      formId
      affiliationOrganization
      affiliationProject
      customer
      readPermission
      additionalReadPermission
      writePermission
      additionalWritePermission
      createdAt
      updatedAt
      creator
      updator
      proxiedUser
      proxyCreator
      liveOrArchived
      chatThreadWithCustomer
      chatThreadInProject
      inhouseCollaboratorTicketId
      inhouseCustomerTicketId
      externalCollaboratorTicketId
      externalCustomerTicketId
      public
      customersCustomer
      parent
      children
      projectOrigin
      projectTarget
      invitationFlag
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      approveFlag
      bookmark
      ticketType
      deadLine
      personInCharge
      status
      sprint
      priority
      processingOrder
      approvePageLink {
        link
        expireTime
      }
      publicType
      publicRange
      lastMessage
      anonymousUserInfo
      anonymousQaStatus
      updatedForCustomer
      updatedForDesk
      footPrint
      viewPermission
      additionalViewPermission
      editPermission
      additionalEditPermission
      chatPermission
      deletedAt
      timeVectorInfo
      promptFlag
      contentPagePdf
      totalToken
      isSynonym
      metaData
      promptData
      deliverStatus
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      ticketId
      dataType
      dataID
      dataLang
      dataValue
      usage
      formId
      affiliationOrganization
      affiliationProject
      customer
      readPermission
      additionalReadPermission
      writePermission
      additionalWritePermission
      createdAt
      updatedAt
      creator
      updator
      proxiedUser
      proxyCreator
      liveOrArchived
      chatThreadWithCustomer
      chatThreadInProject
      inhouseCollaboratorTicketId
      inhouseCustomerTicketId
      externalCollaboratorTicketId
      externalCustomerTicketId
      public
      customersCustomer
      parent
      children
      projectOrigin
      projectTarget
      invitationFlag
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      approveFlag
      bookmark
      ticketType
      deadLine
      personInCharge
      status
      sprint
      priority
      processingOrder
      approvePageLink {
        link
        expireTime
      }
      publicType
      publicRange
      lastMessage
      anonymousUserInfo
      anonymousQaStatus
      updatedForCustomer
      updatedForDesk
      footPrint
      viewPermission
      additionalViewPermission
      editPermission
      additionalEditPermission
      chatPermission
      deletedAt
      timeVectorInfo
      promptFlag
      contentPagePdf
      totalToken
      isSynonym
      metaData
      promptData
      deliverStatus
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      ticketId
      dataType
      dataID
      dataLang
      dataValue
      usage
      formId
      affiliationOrganization
      affiliationProject
      customer
      readPermission
      additionalReadPermission
      writePermission
      additionalWritePermission
      createdAt
      updatedAt
      creator
      updator
      proxiedUser
      proxyCreator
      liveOrArchived
      chatThreadWithCustomer
      chatThreadInProject
      inhouseCollaboratorTicketId
      inhouseCustomerTicketId
      externalCollaboratorTicketId
      externalCustomerTicketId
      public
      customersCustomer
      parent
      children
      projectOrigin
      projectTarget
      invitationFlag
      invitationInfo {
        adminEmail
        adminFirstName
        adminLastName
        orgName
        projectName
        projectInvite
        projectType
        customerEmail
        customerLastName
        customerFirstName
        linkInvite
        guestContractRegister {
          id
          mailAddress
          userId
          firstName
          lastName
          corporationPhone
          corporationName
          departmentName
          subDomain
          password
          contractType
          inviter
          userType
          projectType
          projectName
          ticketId
          projectInvite
        }
        expireAt
        maxGeneralId
        numberGeneralIdLeft
        subDomainInvited
      }
      approveFlag
      bookmark
      ticketType
      deadLine
      personInCharge
      status
      sprint
      priority
      processingOrder
      approvePageLink {
        link
        expireTime
      }
      publicType
      publicRange
      lastMessage
      anonymousUserInfo
      anonymousQaStatus
      updatedForCustomer
      updatedForDesk
      footPrint
      viewPermission
      additionalViewPermission
      editPermission
      additionalEditPermission
      chatPermission
      deletedAt
      timeVectorInfo
      promptFlag
      contentPagePdf
      totalToken
      isSynonym
      metaData
      promptData
      deliverStatus
      deliverInfo {
        status
        deliverAt
        sender
        __typename
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      usage
      affiliationOrganization
      image
      active
      firstName
      middleName
      lastName
      languages
      privilege
      type
      mail
      tel
      agent
      projectInuse
      readNotificationAt
      workSpace
      creator
      createdAt
      updator
      updatedAt
      expireAt
      forgotPasswordSessionExpireAt
      settingNotification
      isGuest
      userSetting
      projectRejected
      workLastForm
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      usage
      affiliationOrganization
      image
      active
      firstName
      middleName
      lastName
      languages
      privilege
      type
      mail
      tel
      agent
      projectInuse
      readNotificationAt
      workSpace
      creator
      createdAt
      updator
      updatedAt
      expireAt
      forgotPasswordSessionExpireAt
      settingNotification
      isGuest
      userSetting
      projectRejected
      workLastForm
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      usage
      affiliationOrganization
      image
      active
      firstName
      middleName
      lastName
      languages
      privilege
      type
      mail
      tel
      agent
      projectInuse
      readNotificationAt
      workSpace
      creator
      createdAt
      updator
      updatedAt
      expireAt
      forgotPasswordSessionExpireAt
      settingNotification
      isGuest
      userSetting
      projectRejected
      workLastForm
    }
  }
`;
export const createUserBookmark = /* GraphQL */ `
  mutation CreateUserBookmark(
    $input: CreateUserBookmarkInput!
    $condition: ModelUserBookmarkConditionInput
  ) {
    createUserBookmark(input: $input, condition: $condition) {
      id
      userId
      bookmarkId
      ticketId
      projectSetting
      bookmarkShareRange
      bookmarkAffiliationProject
      ticketAffiliationProject
      ticketAffiliationOrganization
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const updateUserBookmark = /* GraphQL */ `
  mutation UpdateUserBookmark(
    $input: UpdateUserBookmarkInput!
    $condition: ModelUserBookmarkConditionInput
  ) {
    updateUserBookmark(input: $input, condition: $condition) {
      id
      userId
      bookmarkId
      ticketId
      projectSetting
      bookmarkShareRange
      bookmarkAffiliationProject
      ticketAffiliationProject
      ticketAffiliationOrganization
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const deleteUserBookmark = /* GraphQL */ `
  mutation DeleteUserBookmark(
    $input: DeleteUserBookmarkInput!
    $condition: ModelUserBookmarkConditionInput
  ) {
    deleteUserBookmark(input: $input, condition: $condition) {
      id
      userId
      bookmarkId
      ticketId
      projectSetting
      bookmarkShareRange
      bookmarkAffiliationProject
      ticketAffiliationProject
      ticketAffiliationOrganization
      creator
      createdAt
      updator
      updatedAt
    }
  }
`;
export const createView = /* GraphQL */ `
  mutation CreateView(
    $input: CreateViewInput!
    $condition: ModelViewConditionInput
  ) {
    createView(input: $input, condition: $condition) {
      id
      name
      affiliationOrganization
      affiliationProject
      affiliationView
      visibleRange
      visibleType
      structure
      viewType
      definitionView
      status
      order
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
export const updateView = /* GraphQL */ `
  mutation UpdateView(
    $input: UpdateViewInput!
    $condition: ModelViewConditionInput
  ) {
    updateView(input: $input, condition: $condition) {
      id
      name
      affiliationOrganization
      affiliationProject
      affiliationView
      visibleRange
      visibleType
      structure
      viewType
      definitionView
      status
      order
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
export const deleteView = /* GraphQL */ `
  mutation DeleteView(
    $input: DeleteViewInput!
    $condition: ModelViewConditionInput
  ) {
    deleteView(input: $input, condition: $condition) {
      id
      name
      affiliationOrganization
      affiliationProject
      affiliationView
      visibleRange
      visibleType
      structure
      viewType
      definitionView
      status
      order
      creator
      updator
      createdAt
      updatedAt
    }
  }
`;
